export const SET_TAB = 'SET_TAB';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const SET_START_INFO_LOADED = 'SET_START_INFO_LOADED';

export const SET_ACTIVE_PANEL           = 'SET_ACTIVE_PANEL';
export const SET_ACTIVE_STORY           = 'SET_ACTIVE_STORY';
export const PUSH_HISTORY               = 'PUSH_HISTORY';
export const POP_HISTORY                = 'POP_HISTORY';
export const SET_HISTORY                = 'SET_HISTORY';
export const CLEAR_HISTORY              = 'CLEAR_HISTORY';
