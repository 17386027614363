import React, { useState } from 'react';
import useDidMountEffect from '../hooks/useDidMountEffect';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { SimpleCell, Checkbox } from '@vkontakte/vkui';

import '../../styles/category-check.scss';
import {
	studentAddSelectedCategoryId,
	studentRemoveSelectedCategoryId,
} from '../store/student/actions';
import { icons } from '../services/icons';

const CategoryCheck = ({category, isProfile = false, selectedCategories = null}) => {
	const dispatch = useDispatch();
    const [checked, setChecked] = useState(
    	!isProfile ? false : selectedCategories.includes(category.id)
		);

    // Компонент используется в двух местах: на экране onBoard и PanelProfile. Поэтому передаю флаг isProfile и от него оттакливаюсь
    useDidMountEffect(() => {
			categoryHandler();
		}, [checked]);


		const categoryHandler = () => {
			if (checked) {
				dispatch(studentAddSelectedCategoryId(category.id))
			} else {
				dispatch(studentRemoveSelectedCategoryId(category.id));
			}
		};


    return (
        <SimpleCell
            className="category-check"
            onClick={() => {
							if (isProfile && selectedCategories.length === 3 && checked) {
								return '';
							} else {
								setChecked(!checked);
							}
						}}
            before={
            	<div className="icon-before icon-category" style={{ backgroundImage: `url(${icons[category.tag] !== undefined ? icons[category.tag] : icons.other})` }} />
            }
            after={
            	<Checkbox
								disabled={isProfile && selectedCategories.length <= 3 && checked}
								readOnly
								checked={checked}
							/>
            }
        >
            {category.name}
        </SimpleCell>
    );
};

CategoryCheck.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
};

export default CategoryCheck;
