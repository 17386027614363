import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { studentSetInterests } from '../store/student/actions';
import CategoryCheck from './CategoryCheck';
import { getStudent, setStudentInterest } from '../services/vk';
import useDidMountEffect from '../hooks/useDidMountEffect';

const CategoryCheckList = ({categories, isProfile}) => {
	const dispatch = useDispatch();
	const selectedCategories = useSelector((store) => store.student.selectedCategoriesIds);

	const saveHandler = async () => {
		const setInterestResult = await setStudentInterest(selectedCategories);
	};

	useDidMountEffect(() => {
		if (isProfile) {
			saveHandler();
		}
	}, [selectedCategories]);

    return (
        <div className="category-check__list">
					{categories && categories.map((category) =>
						<CategoryCheck
							selectedCategories={selectedCategories}
							isProfile={isProfile}
							key={category.id}
							category={category} />)
					}
        </div>
    );
};

CategoryCheckList.propTypes = {
	categories: PropTypes.array.isRequired
};

export default CategoryCheckList;
