import * as types from './actionTypes';

const initialState = {
    tabIndex: 1,
    activeModal: null,
};

export const globals = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_TAB:
            return {
                ...state,
                tabIndex: action.tabIndex,
            };

        case types.SET_ACTIVE_MODAL:
            return {
                ...state,
                activeModal: action.activeModal,
            };
				case types.SET_START_INFO_LOADED:
					return {
						...state,
						isStartInfoLoaded: true,
					};
        default:
            return state;
    }
};
