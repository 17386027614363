import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { push, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createHashHistory } from 'history';
import { rootReducer } from './js/store/reducers';
import { schemeChanger } from './js/services/_functions';
import App from './App';
import { initApp } from './js/services/vk';

const history = createHashHistory({
    hashType: 'noslash',
});


const logger = (store) => (next) => (action) => {
    if (process.env.NODE_ENV !== 'production') console.log('dispatching', action);
    return next(action);
};

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history), logger)),
);

store.dispatch(initApp());

const urls = [
    'welcome',
    'onboard',
    'marathon',
    'about-marathon',
    'category',
    'about-author',
    'test',
    'categories',
    'profile',
		'empty',
		'about-author-category',
		'about-marathon-category',
		'about-marathon-profile'
];


const pathRegex = `/:pageId(${urls.join('|')}|)?`;

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<Route path={pathRegex} component={props => <App history={history} pageId={props.match.params.pageId} />} />
		</Router>
	</Provider>,
	document.getElementById('root'),
);
