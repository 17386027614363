import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { homeAboutSelectAuthorId } from '../store/home/actions';

import { Header, Group, CardScroll, Card, Avatar } from '@vkontakte/vkui';
import '../../styles/best-author.scss';

import { numberWithKM, wordPad } from '../services/_functions';

const BestAuthorList = ({ authors, name, history }) => {
	const dispatch = useDispatch();

	const authorHandler = authorId => {
		dispatch(homeAboutSelectAuthorId(authorId));
		if (history && history.location.pathname === '/marathon') {
			dispatch(push('about-author'));
		} else if (history && history.location.pathname === '/category') {
			dispatch(push('about-author-category'));
		}
	};

    const renderList = () => {
        return authors && authors.map((author) => (
            <Card
							className="best-author__item"
							key={`ba${author.id}`}
							onClick={() => authorHandler(author.id)}
						>
                <Avatar
									size={64}
									src={author.photo_200}
								/>
                <div
									className="best-author__text"
								>
									{author.name}
                </div>
                <div className="best-author__subtext">
									{author.members_count !== undefined &&
										numberWithKM(author.members_count) + wordPad(author.members_count, ' подписчик', ' подписчика', ' подписчиков')
									}
									{author.members_count === undefined && 'Закрытая группа'}
                </div>
            </Card>
        ));
    };

    return (
        <Group>
            <Header>{name}</Header>
            <CardScroll className="best-author">{renderList()}</CardScroll>
        </Group>
    );
};

BestAuthorList.propTypes = {
		authors: PropTypes.array,
    name: PropTypes.string,
};

export default BestAuthorList;
