import React from 'react';
import ModalExerciseComplete from './ModalExerciseComplete';

const ModalExerciseCompleteNoTest = ( {id} ) => {
	return (
		<ModalExerciseComplete
			id={id}
			hasNoTest={true}
		/>
	)
};

export default ModalExerciseCompleteNoTest;


