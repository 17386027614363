import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setActiveModal } from '../store/globals/actions';
import {
	studentAddCourseToFavourite,
	studentLeaveCourse,
	studentRemoveCourseToFavourite,
} from '../services/vk';
import {
	homeAboutSetSelectedMarathonIsActiveCourse,
	homeAboutSetSelectedMarathonIsFavourite,
} from '../store/home/actions';

import { Div, Button } from '@vkontakte/vkui';

import Icon24FavoriteOutline from '@vkontakte/icons/dist/24/favorite_outline';
import Icon28UnfavoriteOutline from '@vkontakte/icons/dist/28/unfavorite_outline';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import Icon24Add from '@vkontakte/icons/dist/24/add';

import RatingStars from './RatingStars';
import { wordPad } from '../services/_functions';

const BlockAboutMarathon = ({ courseId, id, cover, name, student, done, rating, description, video, isFavorite, isActiveCourse }) => {
	const dispatch = useDispatch();
	const renderVideo = () => {
		return <iframe src={video} frameBorder="0" allowFullScreen></iframe>;
	};

	const joinMarathon = () => {
		dispatch(setActiveModal('modalSubs'));
	};


	const leaveMarathon = async () => {
		const leaveResult = await studentLeaveCourse(id, courseId);
		if (leaveResult.result === 'success' && leaveResult.data === 1) {
			dispatch(homeAboutSetSelectedMarathonIsActiveCourse(false));
		}
	};

	const addToFaves = async () => {
		const addToFavesResult = await studentAddCourseToFavourite(id, courseId);
		if (addToFavesResult.result === 'success' && addToFavesResult.data === 1) {
			dispatch(homeAboutSetSelectedMarathonIsFavourite(true));
		}
	};

	const removeFromFaves = async () => {
		const removeFromFavesResult = await studentRemoveCourseToFavourite(id, courseId);
		if (removeFromFavesResult.result === 'success' && removeFromFavesResult.data === 1) {
			dispatch(homeAboutSetSelectedMarathonIsFavourite(false));
		}
	};

	return (
		<Div>
			<div className="marathon-about__cover" style={{ backgroundImage: `url(${cover})` }}/>
			<div className="marathon-about__name">{name}</div>
			<div className="marathon-about__stats">
				<span>{student + wordPad(student, ' студент', ' студента', ' студентов')}</span>
				<RatingStars size={9} rating={rating}/>
			</div>
			{done && (
				<div className="marathon-about__done">
					<span>Марафон пройден</span>
					<Icon24Done fill="818c99" width={12} height={12}/>
				</div>
			)}
			<div className="marathon-about__action">
				{!done && isActiveCourse &&
				<Button
					onClick={leaveMarathon}
					mode="secondary"
					before={
						<Icon24Done width={20} height={20}/>
					}
				>
					Вы подписаны на марафон
				</Button>
				}
				{!done && !isActiveCourse && <Button
					onClick={joinMarathon}
					before={<Icon24Add width={20} height={20}/>}
				>
					Подписаться на марафон
				</Button>
				}
				{isFavorite &&
				<Button onClick={removeFromFaves} mode="secondary">
					<Icon28UnfavoriteOutline width={20} height={20}/>
				</Button>
				}
				{!isFavorite &&
				<Button onClick={addToFaves} mode="secondary">
					<Icon24FavoriteOutline width={20} height={20}/>
				</Button>
				}
			</div>
			{description && <div className="marathon-about__description">{description}</div>}
			{video !== '' &&
			<div className="marathon-about__video">
				{renderVideo()}
			</div>
			}
		</Div>
	);
};

BlockAboutMarathon.propTypes = {
	cover: PropTypes.string,
	name: PropTypes.string,
	student: PropTypes.number,
	done: PropTypes.bool,
	rating: PropTypes.number,
	description: PropTypes.string,
	btnSub: PropTypes.func,
};

export default BlockAboutMarathon;
