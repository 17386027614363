import vkBridge from '@vkontakte/vk-bridge';
import { setAuthTokenFail, setAuthTokenSuccess, setInsets } from '../store/vk/actions';
import { store } from '../../index';
import { getAppId, getNewRequestId, wordPad } from './_functions';
import { COURSES_RECOMMENDED_PER_PAGE } from './_globals';

const API_VERSION = '5.124';

// Инит приложения
export const initApp = () => dispatch => {
	const callback = e => {

		if (e.detail.type === 'VKWebAppUpdateConfig') {
			document.body.setAttribute('scheme', e.detail.data.scheme);
			dispatch(setInsets(e.detail.data.insets));
			if (e.detail.data.appearance === 'light') {
				setStatusBarDark();
			} else {
				setStatusBarLight();
			}
		}
	};
	vkBridge.subscribe(callback);
	vkBridge.send('VKWebAppInit', {});
};



export const setStatusBarDark = () => {
	vkBridge.send('VKWebAppSetViewSettings', { status_bar_style: 'dark' });
};

export const setStatusBarLight = () => {
	vkBridge.send('VKWebAppSetViewSettings', { status_bar_style: 'light' });
};


// Скролл
export const goToTop = (top = 1) => {
	return new Promise(() => {
		const handler = async e => {
			e = e.detail;
			let found = false;

			if (e.type === 'VKWebAppScrollResult') {
				found = true;
			}

			if (found) {
				vkBridge.unsubscribe(handler);
			}
		};
		vkBridge.subscribe(handler);
		vkBridge.send('VKWebAppScroll', { top, speed: 1000 });
	});
};

// Получение токена
export const getAuthToken = (scope = '') => async dispatch => {
	return new Promise(resolve => {
		const handler = async e => {
			e = e.detail;
			let found = false;
			switch (e.type) {
				case 'VKWebAppAccessTokenReceived':
					found = true;
					dispatch(setAuthTokenSuccess(e.data.access_token));
					resolve(e.data.access_token);
					break;

				case 'VKWebAppAccessTokenFailed':
					found = true;
					dispatch(setAuthTokenFail());
					resolve('');
					break;

				default:
					break;
			}
			if (found) {
				vkBridge.unsubscribe(handler);
			}
		};
		vkBridge.subscribe(handler);
		vkBridge.send('VKWebAppGetAuthToken', {
			app_id: store.getState().vk.appId,
			scope,
		});
	});
};

// Подписка на сообщество
export const joinGroup = groupId => {
	return new Promise(resolve => {
		const handler = async e => {
			e = e.detail;
			let found = false;
			switch (e.type) {
				case 'VKWebAppJoinGroupResult':
					found = true;
					resolve(true);
					break;

				case 'VKWebAppJoinGroupFailed':
					found = true;
					resolve(false);
					break;

				default:
					break;
			}
			if (found) {
				vkBridge.unsubscribe(handler);
			}
		};
		vkBridge.subscribe(handler);
		vkBridge.send('VKWebAppJoinGroup', { group_id: groupId });
	});
};

// Отписаться от сообщества
export const leaveGroup = groupId => {
	return new Promise(resolve => {
		const handler = async e => {
			e = e.detail;
			let found = false;
			switch (e.type) {
				case 'VKWebAppLeaveGroupResult':
					found = true;
					resolve(true);
					break;

				case 'VKWebAppLeaveGroupFailed':
					found = true;
					resolve(false);
					break;

				default:
					break;
			}
			if (found) {
				vkBridge.unsubscribe(handler);
			}
		};
		vkBridge.subscribe(handler);
		vkBridge.send('VKWebAppLeaveGroup', { group_id: groupId });
	});
};

// Запостить на стену
export const wallPost = (title, publicId, publicName, count) => {
	return new Promise(resolve => {
		const handler = async e => {
			e = e.detail;
			let found = false;
			switch (e.type) {
				case 'VKWebAppShowWallPostBoxResult':
					found = true;
					resolve(true);
					break;

				case 'VKWebAppShowWallPostBoxFailed':
					found = true;
					resolve(false);
					break;

				default:
					break;
			}
			if (found) {
				vkBridge.unsubscribe(handler);
			}
		};
		vkBridge.subscribe(handler);
		vkBridge.send('VKWebAppShowWallPostBox', {
			message: `Я прошел курс «${title}» образовательной платформы от сообщества [id${publicId}|${publicName}] и набрал ${count} ${wordPad(
				count,
				'балл',
				'балла',
				'баллов',
			)}, пройдите теперь и Вы`,
			attachments: `https://vk.com/app${getAppId()}`,
		});
	});
};

// Ресайзим фрейм
export const resizeFrame = (offset = 0) => {
	const resize = () => {
		const height = document.querySelector('#root #h-container').getBoundingClientRect().height + offset;
		vkBridge.send('VKWebAppResizeWindow', { height });
	};

	setTimeout(resize, 1);
};

// Получаем студента
export const getStudent = () => {
	return apiRequest(
		'education.getStudent',
		{},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Создаем студента
export const createStudent = () => {
	return apiRequest(
		'education.createStudent',
		{},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем категории
export const getCategories = () => {
	return apiRequest(
		'education.getCategories',
		{},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Сохраняем интересы студента
export const setStudentInterest = categoriesIds => {
	return apiRequest(
		'education.setStudentInterest',
		{ category_id: categoriesIds.join(',') },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем шаги курса
export const getCourseSteps = (authorId, courseId, offset = 0, count = 42) => {
	return apiRequest(
		'education.getCourseSteps',
		{ author_id: authorId, course_id: courseId, offset, count },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем курс
export const getCourse = (authorId, courseId) => {
	return apiRequest(
		'education.getCourse',
		{ author_id: authorId, course_id: courseId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем паблик
export const getPublic = groupId => {
	return apiRequest(
		'groups.getById',
		{ group_id: groupId, extended: 1, fields: 'members_count,photo_100,cover' },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Проверить участник группы или нет
export const isPublicMember = (groupId, userId) => {
	return apiRequest(
		'groups.isMember',
		{ group_id: groupId, user_id: userId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем стартовую инфу
export const getStartInfo = () => {
	let code = '';
	code += 'var active = API.education.getStudentActiveCourses();';
	code += 'var authors = API.education.searchAuthors({category_id: 0, count: 5});';
	code += `var recommended = API.education.searchCourses({category_id: 0, text: "", count: ${COURSES_RECOMMENDED_PER_PAGE}});`;
	code += 'var groupsIds = authors.items@.id;';
	code += 'var i = 0;';
	code += 'var idsList = "";';
	code += 'while (i < groupsIds.length) {';
	code += 'idsList = idsList + (groupsIds[i] * (-1)) + ",";';
	code += 'i = i + 1;';
	code += '}';
	code += 'var groups = API.groups.getById({group_ids: idsList, fields: "members_count"});';
	code += 'return {active: active, authors: authors, recommended: recommended, groups: groups};';

	return apiRequest(
		'execute',
		{ code },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем инфу по категории
export const getCategoryInfo = categoryId => {
	let code = '';
	code += `var top = API.education.searchCourses({category_id: ${categoryId}, text: "", count: 1});`;
	code += `var authors = API.education.searchAuthors({category_id: ${categoryId}, count: 5});`;
	code += `var recommended = API.education.searchCourses({category_id: ${categoryId}, text: "", count: ${COURSES_RECOMMENDED_PER_PAGE}, offset: 1});`;
	code += 'var groupsIds = authors.items@.id;';
	code += 'var i = 0;';
	code += 'var idsList = "";';
	code += 'while (i < groupsIds.length) {';
	code += 'idsList = idsList + (groupsIds[i] * (-1)) + ",";';
	code += 'i = i + 1;';
	code += '}';
	code += 'var groups = API.groups.getById({group_ids: idsList, fields: "members_count"});';
	code += 'return {top: top, authors: authors, recommended: recommended, groups: groups};';

	return apiRequest(
		'execute',
		{ code },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем последние проходимые курсы пользователя
export const getRecommendedCourses = (categoryId = 0, offset = 0, count = COURSES_RECOMMENDED_PER_PAGE) => {
	return apiRequest(
		'education.searchCourses',
		{ category_id: categoryId, offset, count, text: '' },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем активные курсы студента
export const getStudentActiveCourses = (offset = 0, count = COURSES_RECOMMENDED_PER_PAGE) => {
	return apiRequest(
		'education.getStudentActiveCourses',
		{ offset, count },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем избранные курсы студента
export const getStudentFavouriteCourses = (offset = 0, count = COURSES_RECOMMENDED_PER_PAGE) => {
	return apiRequest(
		'education.getStudentFavoriteCourses',
		{ offset, count },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем завершенные курсы студента
export const getStudentCompleteCourses = (offset = 0, count = COURSES_RECOMMENDED_PER_PAGE) => {
	return apiRequest(
		'education.getStudentCompleteCourses',
		{ offset, count },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получение курсов автора
export const getAboutAuthorCourses = (authorId, offset, count = COURSES_RECOMMENDED_PER_PAGE) => {
	return apiRequest(
		'education.searchCourses',
		{ text: '', offset, count, author_id: authorId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Поиск по курсам
export const getSearchCourses = (text, offset, categoryId = 0, count = COURSES_RECOMMENDED_PER_PAGE) => {
	return apiRequest(
		'education.searchCourses',
		{ text, offset, count, category_id: categoryId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Подписываемся на курс
export const studentStartCourse = (authorId, courseId) => {
	return apiRequest(
		'education.studentStartCourse',
		{ author_id: authorId, course_id: courseId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Отписыаемся от курса
export const studentLeaveCourse = (authorId, courseId) => {
	return apiRequest(
		'education.studentLeaveCourse',
		{ author_id: authorId, course_id: courseId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Добавляем курс в избранное
export const studentAddCourseToFavourite = (authorId, courseId) => {
	return apiRequest(
		'education.studentAddCourseToFavorite',
		{ author_id: authorId, course_id: courseId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Убираем курс из избранного
export const studentRemoveCourseToFavourite = (authorId, courseId) => {
	return apiRequest(
		'education.studentRemoveCourseFromFavorite',
		{ author_id: authorId, course_id: courseId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Ставим статус "прочитано" для статьи
export const submitArticle = (authorId, courseId, stepId) => {

	return apiRequest(
		'education.submitArticle',
		{ author_id: authorId, course_id: courseId, step_id: stepId },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Получаем вопросы теста
export const getStepTest = (authorId, courseId, stepId) => {
	return apiRequest(
		'education.getStepTest',
		{ author_id: authorId, course_id: courseId, step_id: stepId, count: 42, offset: 0 },
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Ответить на текстовый вопрос
export const submitQuestionText = (authorId, courseId, stepId, questionId, answerText) => {
	return apiRequest(
		'education.submitQuestionText',
		{
			author_id: authorId,
			course_id: courseId,
			step_id: stepId,
			question_id: questionId,
			answer_text: answerText,
		},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Ответить на вопрос с выбором
export const submitQuestionSelect = (authorId, courseId, stepId, questionId, answerIds) => {
	return apiRequest(
		'education.submitQuestionSelect',
		{
			author_id: authorId,
			course_id: courseId,
			step_id: stepId,
			question_id: questionId,
			answer_ids: answerIds.join(','),
		},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Ответить на вопрос с сопоставлением
export const submitQuestionMatch = (authorId, courseId, stepId, questionId, pairsIds) => {
	return apiRequest(
		'education.submitQuestionMatch',
		{
			author_id: authorId,
			course_id: courseId,
			step_id: stepId,
			question_id: questionId,
			pairs: JSON.stringify(pairsIds),
		},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Выставить рейтинг упражнению
export const setStepRating = (authorId, courseId, stepId, rating) => {
	return apiRequest(
		'education.setStepRating',
		{
			author_id: authorId,
			course_id: courseId,
			step_id: stepId,
			rating,
		},
		data => {
			return { result: 'success', data };
		},
		e => {
			return { result: 'fail', e };
		},
	);
};

// Открыть просмотр статьи
export const openArticle = articleUrl => {
	return new Promise(resolve => {
		const params = { url: articleUrl };
		const handler = async e => {
			e = e.detail;
			let found = false;

			switch (e.type) {
				case 'VKWebAppOpenArticleResult':
					break;

				case 'VKWebAppArticleClosed':
					found = true;
					resolve(e.data);
					break;

				case 'VKWebAppOpenArticleFailed':
					found = true;
					resolve(false);
					break;

				default:
					break;
			}
			if (found) {
				vkBridge.unsubscribe(handler);
			}
		};
		vkBridge.subscribe(handler);
		vkBridge.send('VKWebAppOpenArticle', params);
	});
};

export const apiRequest = (
	method,
	params = {},
	successCallback = undefined,
	failCallback = undefined,
	customRequestId = undefined,
) => {
	const requestId = customRequestId !== undefined ? customRequestId : getNewRequestId();

	return new Promise((resolve, reject) => {
		const handler = async e => {
			e = e.detail;
			let found = false;

			if (e.data.request_id === requestId) {
				switch (e.type) {
					case 'VKWebAppCallAPIMethodResult':
						found = true;
						if (customRequestId === undefined) {
							if (successCallback !== undefined) {
								resolve(successCallback(e.data.response));
							}
							resolve(e.data.response);
						} else {
							if (successCallback !== undefined) {
								resolve(
									successCallback({
										response: e.data.response,
										request_id: e.data.request_id,
									}),
								);
							}
							resolve({ response: e.data.response, request_id: e.data.request_id });
						}
						break;

					case 'VKWebAppCallAPIMethodFailed':
						found = true;
						if (customRequestId === undefined) {
							if (failCallback !== undefined) {
								resolve(failCallback(e.data));
							}
							resolve(e.data);
						} else {
							if (failCallback !== undefined) {
								resolve(
									failCallback({
										response: e.data.response,
										request_id: e.data.request_id,
									}),
								);
							}
							resolve({ response: e.data.response, request_id: e.data.request_id });
						}
						break;

					default:
						break;
				}
				if (found) {
					vkBridge.unsubscribe(handler);
				}
			}
		};

		vkBridge.subscribe(handler);

		params.access_token = store.getState().vk.accessToken;

		if (params.v === undefined) {
			params.v = API_VERSION;
		}

		vkBridge.send('VKWebAppCallAPIMethod', {
			method,
			params,
			request_id: requestId,
		});
	});
};
