export const TEST_SET_BREADCUMB = 'TEST_SET_BREADCUMB';
export const TEST_SET_TEST = 'TEST_SET_TEST';
export const TEST_SET_TESTS_WITHOUT_ANSWERS = 'TEST_SET_TESTS_WITHOUT_ANSWERS';
export const TEST_REMOVE_ANSWERED_QUESTIONS = 'TEST_REMOVE_ANSWERED_QUESTIONS';
export const TEST_RESET = 'TEST_RESET';
export const TEST_NEXT_QUESTION = 'TEST_NEXT_QUESTION';
export const TEST_SET_STEP_ID = 'TEST_SET_STEP_ID';
export const TEST_SET_PREV_TEST_INFO = 'TEST_SET_PREV_TEST_INFO';
export const TEST_SET_TEST_SHOW_ONLY = 'TEST_SET_TEST_SHOW_ONLY';
export const TEST_SET_RIGHT_QUESTIONS_MODAL_FROM = 'TEST_SET_RIGHT_QUESTIONS_MODAL_FROM';
export const TEST_SET_RIGHT_QUESTIONS_MODAL_TO = 'TEST_SET_RIGHT_QUESTIONS_MODAL_TO';
export const TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_FROM = 'TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_FROM';
export const TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_TO = 'TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_TO';
export const TEST_SET_IS_LAST_STEP = 'TEST_SET_IS_LAST_STEP';
