import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goBack, replace } from 'react-router-redux';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderBack from '@vkontakte/vkui/dist/components/PanelHeaderBack/PanelHeaderBack';

const BlockPanel = ({ id, transparent, name, children, centered = false, history }) => {
    const dispatch = useDispatch();

    const openBack = () => {
		dispatch(goBack());
    };

    return (
        <Panel id={id} centered={centered}>
            <PanelHeader
							separator={false}
							transparent={transparent}
							left={
								<PanelHeaderBack
								onClick={openBack} />
							}>
                {name}
            </PanelHeader>
            {children}
        </Panel>
    );
};

BlockPanel.propTypes = {
    id: PropTypes.string.isRequired,
    transparent: PropTypes.bool,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default BlockPanel;
