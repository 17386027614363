import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal } from '../store/globals/actions';
import { push } from 'react-router-redux';
import { homeAboutSetSelectedMarathonIsCompleteCourse } from '../store/home/actions';

import { ModalCard, Button } from '@vkontakte/vkui';

import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';
import '../../styles/modal.scss';
import { wallPost } from '../services/vk';


const ModalMarathonComplete = ({ id }) => {
	const dispatch = useDispatch();
	const selectedMarathon = useSelector(state => state.home.selectedMarathon);
	const aboutCoursePublic = useSelector(state => state.home.aboutCoursePublic);
	const lastRightQuestions = useSelector(state => state.test.lastRightQuestions);
	const lastTotalQuestions = useSelector(state => state.test.lastTotalQuestions);


	useEffect(() => {
		dispatch(homeAboutSetSelectedMarathonIsCompleteCourse(true));
	}, []);

	const closeMdl = () => {
		dispatch(setActiveModal(null));
	};

	const openMarathons = () => {
		dispatch(setActiveModal(null));
		dispatch(push('marathon'));
	};

	const share = () => {
		wallPost(selectedMarathon.title, aboutCoursePublic.id, aboutCoursePublic.name, lastRightQuestions);
	};

	console.log(lastRightQuestions, lastTotalQuestions);

	return (
		<ModalCard
			id={id}
			onClose={closeMdl}
			icon={<Icon56CheckCircleOutline width={48} height={48}/>}
			header="Марафон завершен"
			caption={
				<>
                    <span>Вы успешно завершили марафон.
											<p>Правильных ответов {lastRightQuestions}/{lastTotalQuestions}</p></span>
					<Button
						mode="tertiary"
						onClick={share}
					>
						Поделиться с друзьями
					</Button>
				</>
			}
			actionsLayout="vertical"
			actions={[
				{
					title: 'К другим марафонам',
					mode: 'primary',
					size: 'xl',
					action: openMarathons,
				},
			]}
		/>
	);
};

ModalMarathonComplete.propTypes = {
	id: PropTypes.string,
	setActiveModal: PropTypes.func,
};

export default ModalMarathonComplete;
