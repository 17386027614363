import * as types from './actionTypes';
import { copyArray } from '../../services/_functions';

const COURSE = {
    items: [],
    next_offset: 0,
    offset: 0,
    total: 0,
};

const initialState = {
    tabMarathons: {
        studentActiveCourses: [],
        bestAuthors: [],
        recommendedCourses: copyArray(COURSE),
    },
    tabSecond: {
        topCourse: {},
        bestAuthors: [],
        recommendedCourses: copyArray(COURSE),
    },
    tabProfile: {
        studentActiveCourses: copyArray(COURSE),
        studentFavouriteCourses: copyArray(COURSE),
        studentCompletedCourses: copyArray(COURSE),
    },
    search: {
        searchText: '',
        searchCourses: copyArray(COURSE),
        searchIsLoading: false,
    },
    selectedMarathon: {},
    aboutMarathonsAmount: 0,
    aboutCourseSteps: [],
    aboutCoursePublic: {},
    selectedAuthorId: 0,
    aboutAuthor: {},
    aboutAuthorMarathons: copyArray(COURSE),
    aboutAuthorIsMember: -1,
    aboutAuthorBread: '',
    isNextCourseStart: false,
};

export const home = (state = initialState, action) => {
    switch (action.type) {
        case types.HOME_MARATHONS_SET_STUDENT_ACTIVE_COURSES:
            return {
                ...state,
                tabMarathons: {
                    ...state.tabMarathons,
                    studentActiveCourses: action.studentActiveCourses,
                },
            };

        case types.HOME_MARATHONS_SET_BEST_AUTHORS: {
            const allBestAuthors = [];
            const allGroups = {};
            if (action.groups !== false) {
                action.groups.forEach(group => (allGroups[group.id * -1] = group));
            }
            action.bestAuthors.forEach(author => {
                const newAuthor = author;
                newAuthor.name = allGroups[author.id] !== undefined ? allGroups[author.id].name : '';
                newAuthor.photo_200 = allGroups[author.id] !== undefined ? allGroups[author.id].photo_200 : '';
                newAuthor.members_count = allGroups[author.id] !== undefined ? allGroups[author.id].members_count : '';
                allBestAuthors.push(newAuthor);
            });

            return {
                ...state,
                tabMarathons: {
                    ...state.tabMarathons,
                    bestAuthors: allBestAuthors,
                },
            };
        }

        case types.HOME_MARATHONS_SET_RECOMMENDED_COURSES:
            return {
                ...state,
                tabMarathons: {
                    ...state.tabMarathons,
                    recommendedCourses: action.recommendedCourses,
                },
            };

        case types.HOME_SECOND_SET_TOP_COURSE:
            return {
                ...state,
                tabSecond: {
                    ...state.tabSecond,
                    topCourse: action.topCourse,
                },
            };

        case types.HOME_SECOND_SET_BEST_AUTHORS: {
            const allBestAuthors = [];
            const allGroups = {};
            if (action.groups !== false) {
                action.groups.forEach(group => (allGroups[group.id * -1] = group));
            }
            action.bestAuthors.forEach(author => {
                const newAuthor = author;
                newAuthor.name = allGroups[author.id] !== undefined ? allGroups[author.id].name : '';
                newAuthor.photo_200 = allGroups[author.id] !== undefined ? allGroups[author.id].photo_200 : '';
                newAuthor.members_count = allGroups[author.id] !== undefined ? allGroups[author.id].members_count : '';
                allBestAuthors.push(newAuthor);
            });

            return {
                ...state,
                tabSecond: {
                    ...state.tabSecond,
                    bestAuthors: allBestAuthors,
                },
            };
        }

        case types.HOME_SECOND_SET_RECOMMENDED_COURSES:
            return {
                ...state,
                tabSecond: {
                    ...state.tabSecond,
                    recommendedCourses: action.recommendedCourses,
                },
            };

        case types.HOME_PROFILE_SET_ACTIVE_COURSES:
            return {
                ...state,
                tabProfile: {
                    ...state.tabProfile,
                    studentActiveCourses: action.studentActiveCourses,
                },
            };

        case types.HOME_PROFILE_SET_FAVOURITE_COURSES:
            return {
                ...state,
                tabProfile: {
                    ...state.tabProfile,
                    studentFavouriteCourses: action.studentFavouriteCourses,
                },
            };

        case types.HOME_PROFILE_SET_COMPLETED_COURSES:
            return {
                ...state,
                tabProfile: {
                    ...state.tabProfile,
                    studentCompletedCourses: action.studentCompletedCourses,
                },
            };

        case types.HOME_SET_SEARCH_TEXT:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchText: action.searchText,
                },
            };

        case types.HOME_SET_SEARCH_COURSES:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchCourses: action.searchCourses,
                },
            };

        case types.HOME_CLEAR_SEARCH_COURSES:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchCourses: copyArray(COURSE),
                },
            };

        case types.HOME_SET_SEARCH_IS_LOADING:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchIsLoading: action.searchIsLoading,
                },
            };

        case types.HOME_SELECT_MARATHON:
            return {
                ...state,
                selectedMarathon: action.selectedMarathon,
            };

        case types.HOME_ABOUT_SET_MARATHONS_AMOUNT:
            return {
                ...state,
                aboutMarathonsAmount: action.aboutMarathonsAmount,
            };

        case types.HOME_ABOUT_SET_COURSE_STEPS:
            return {
                ...state,
                aboutCourseSteps: action.aboutCourseSteps,
            };

        case types.HOME_ABOUT_SET_COURSE_PUBLIC:
            return {
                ...state,
                aboutCoursePublic: action.aboutCoursePublic,
            };

        case types.HOME_RESET_COURSE_INFO:
            return {
                ...state,
                aboutMarathonsAmount: 0,
                aboutCourseSteps: [],
                aboutCoursePublic: {},
                isNextCourseStart: false,
            };

        case types.HOME_ABOUT_SELECT_AUTHOR_ID:
            return {
                ...state,
                selectedAuthorId: action.selectedAuthorId,
            };

        case types.HOME_ABOUT_SET_AUTHOR:
            return {
                ...state,
                aboutAuthor: action.aboutAuthor,
            };

        case types.HOME_ABOUT_SET_AUTHOR_MARATHONS:
            return {
                ...state,
                aboutAuthorMarathons: action.aboutAuthorMarathons,
            };

        case types.HOME_ABOUT_SET_AUTHOR_IS_MEMBER:
            return {
                ...state,
                aboutAuthorIsMember: action.aboutAuthorIsMember,
            };

        case types.HOME_ABOUT_SET_AUTHOR_BREAD:
            return {
                ...state,
                aboutAuthorBread: action.aboutAuthorBread,
            };

        case types.HOME_ABOUT_SET_SELECTED_MARATHON_IS_ACTIVE_COURSE: {
            const newSelectedMarathon = { ...state.selectedMarathon };
            newSelectedMarathon.is_active_course = action.isActiveCourse;

            const newAboutAuthorMarathons = [];
            state.aboutAuthorMarathons.items.forEach(m => {
                if (m.course_id === state.selectedMarathon.course_id) {
                    m.is_active_course = action.isActiveCourse;
                }
                newAboutAuthorMarathons.push(m);
            });

            return {
                ...state,
                selectedMarathon: newSelectedMarathon,
                aboutAuthorMarathons: {
                    ...state.aboutAuthorMarathons,
                    items: newAboutAuthorMarathons,
                },
            };
        }

        case types.HOME_ABOUT_SET_SELECTED_MARATHON_IS_FAVOURITE: {
            const newSelectedMarathon = { ...state.selectedMarathon };
            newSelectedMarathon.is_favorite = action.isFavourite;

            const newAboutAuthorMarathons = [];
            state.aboutAuthorMarathons.items.forEach(m => {
                if (m.course_id === state.selectedMarathon.course_id) {
                    m.is_favorite = action.isFavourite;
                }
                newAboutAuthorMarathons.push(m);
            });

            return {
                ...state,
                selectedMarathon: newSelectedMarathon,
                aboutAuthorMarathons: {
                    ...state.aboutAuthorMarathons,
                    items: newAboutAuthorMarathons,
                },
            };
        }

        case types.HOME_ABOUT_SET_SELECTED_MARATHON_IS_COMPLETE_COURSE: {
            const newSelectedMarathon = { ...state.selectedMarathon };
            newSelectedMarathon.is_complete_course = action.isCompleteCourse;

            const newAboutAuthorMarathons = [];
            state.aboutAuthorMarathons.items.forEach(m => {
                if (m.course_id === state.selectedMarathon.course_id) {
                    m.is_complete_course = action.isCompleteCourse;
                }
                newAboutAuthorMarathons.push(m);
            });

            return {
                ...state,
                selectedMarathon: newSelectedMarathon,
                aboutAuthorMarathons: {
                    ...state.aboutAuthorMarathons,
                    items: newAboutAuthorMarathons,
                },
            };
        }

        case types.HOME_ABOUT_SET_COURSE_STEPS_IS_ARTICLE_READ: {
            const newAboutAuthorCourseSteps = [];
            state.aboutCourseSteps.forEach(m => {
                if (m.step_id === action.stepId) {
                    m.is_article_read = true;
                }
                newAboutAuthorCourseSteps.push(m);
            });

            return {
                ...state,
                aboutCourseSteps: newAboutAuthorCourseSteps,
            };
        }

        case types.HOME_ABOUT_SET_COURSE_STEPS_IS_TEST_PASSED: {
            const newAboutAuthorCourseSteps = [];
            state.aboutCourseSteps.forEach(m => {
                if (m.step_id === action.stepId) {
                    m.is_test_passed = true;
                }
                newAboutAuthorCourseSteps.push(m);
            });

            return {
                ...state,
                aboutCourseSteps: newAboutAuthorCourseSteps,
            };
        }

        case types.HOME_ABOUT_SET_COURSE_STEP: {
            const newAboutAuthorCourseSteps = [];
            state.aboutCourseSteps.forEach(m => {
                if (m.step_id === action.step.step_id) {
                    newAboutAuthorCourseSteps.push(action);
                } else {
                    newAboutAuthorCourseSteps.push(m);
                }
            });

            return {
                ...state,
                aboutCourseSteps: newAboutAuthorCourseSteps,
            };
        }

        case types.HOME_RESET_AUTHOR_INFO:
            return {
                ...state,
                selectedAuthorId: 0,
                aboutAuthor: {},
                aboutAuthorMarathons: copyArray(COURSE),
                aboutAuthorIsMember: -1,
                aboutAuthorBread: '',
            };

        case types.HOME_ABOUT_SET_IS_NEXT_COURSE_START:
            return {
                ...state,
                isNextCourseStart: action.isNextCourseStart,
            };

        default:
            return state;
    }
};
