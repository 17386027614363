import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Spinner } from '@vkontakte/vkui';

import BestAuthorList from '../component/BestAuthorList';
import MarathonList from '../component/MarathonList';
import BlockTopMarathon from '../component/BlockTopMarathon';
import BlockPanel from '../component/BlockPanel';
import {
	homeSecondSetRecommendedCourses,
} from '../store/home/actions';
import { getRecommendedCourses } from '../services/vk';

const PanelCategory = ({ id, history }) => {
	const dispatch = useDispatch();
	const [panelLoading, setPanelLoading] = useState(false);
	const [loadCounter, setLoadCounter] = useState(12);
	const panelTitle = useSelector(state => state.student.categoryTitle);
	const categoryId = useSelector(state => state.student.categoryId);
	const topCourse = useSelector(state => state.home.tabSecond.topCourse);
	const secondBestAuthors = useSelector(state => state.home.tabSecond.bestAuthors);
	const secondRecommendedCourses = useSelector(state => state.home.tabSecond.recommendedCourses);

	const infiniteScrollLoader = async () => {
		const getDocHeight = () => {
			const D = document;
			return Math.max(
				D.body.scrollHeight, D.documentElement.scrollHeight,
				D.body.offsetHeight, D.documentElement.offsetHeight,
				D.body.clientHeight, D.documentElement.clientHeight,
			);
		};

		const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
		const docHeight = getDocHeight();
		const trackLength = docHeight - windowHeight;
		const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

		if (trackLength === scrollTop) {

			setPanelLoading(true);
			const loadedRecommendedCourses = await getRecommendedCourses(categoryId, 1, loadCounter + 12);
			if (loadedRecommendedCourses.result === 'success') {
				if (loadedRecommendedCourses.data.total === secondRecommendedCourses.total) {
					window.removeEventListener('scroll', infiniteScrollLoader);
					dispatch(homeSecondSetRecommendedCourses(loadedRecommendedCourses.data));
					setPanelLoading(false);
				} else {
					dispatch(homeSecondSetRecommendedCourses(loadedRecommendedCourses.data));
					setLoadCounter(loadCounter + 12);
					setPanelLoading(false);
				}
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', infiniteScrollLoader);
		return () => window.removeEventListener('scroll', infiniteScrollLoader);
	}, [loadCounter]);

	useEffect(() => {
		if (secondRecommendedCourses.items.length === secondRecommendedCourses.total - 1) {
			window.removeEventListener('scroll', infiniteScrollLoader)
		}
	}, []);

	return (
		<BlockPanel
			id={id}
			name={panelTitle}
			history={history}
			centered={secondRecommendedCourses.items.length === 0 && secondBestAuthors.length === 0 && Object.keys(topCourse).length === 0}
		>
			{topCourse.title !== undefined &&
				<BlockTopMarathon
					image={
						topCourse.cover_photo !== undefined && topCourse.cover_photo[0].e !== undefined
							? topCourse.cover_photo[0].e.url
							: ''
					}
					title={topCourse.title}
					student={topCourse.students_count}
					rating={topCourse.rating}
					exercise={topCourse.step_count !== undefined ? topCourse.step_count : 0}
					topCourse={topCourse}
				/>
			}
			{secondBestAuthors.length > 0 &&
			<BestAuthorList
				name="Лучшие авторы"
				authors={secondBestAuthors}
				history={history}
			/>
			}
			{secondRecommendedCourses.items.length > 0 && !panelLoading && (
				<MarathonList
					title="Марафоны"
					marathons={secondRecommendedCourses.items}
					history={history}
				/>
			)}
			{secondRecommendedCourses.items.length === 0 && secondBestAuthors.length === 0 && Object.keys(topCourse).length === 0 &&
				<span className="panel-category__dummy">В этой категории пока нет марафонов</span>
			}
			{panelLoading && <Spinner/>}
			<div style={{ marginTop: '12px' }}/>
		</BlockPanel>
	);
};

PanelCategory.propTypes = {
	id: PropTypes.string,
};

export default PanelCategory;
