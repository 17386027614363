import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import ModalCard from '@vkontakte/vkui/dist/components/ModalCard/ModalCard';
import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';
import RatingSet from './RatingSet';
import '../../styles/modal.scss';
import { setActiveModal } from '../store/globals/actions';
import { setStepRating } from '../services/vk';
import { openTest } from '../services/_functions';
import { goBack } from 'react-router-redux';
import { setTestShowOnly } from '../store/test/actions';

const ModalExerciseComplete = ({ id, hasNoTest = false }) => {
	const dispatch = useDispatch();
	const selectedMarathon = useSelector(state => state.home.selectedMarathon);
	const stepId = useSelector(state => state.test.stepId);
	const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
	const rightQuestions = useSelector(state => state.test.rightQuestions);
	const totalQuestions = useSelector(state => state.test.totalQuestions);
	const isLastStep = useSelector(state => state.test.isLastStep);
	const [voteStars, setVoteStars] = useState(0);
	const [nearestStep, setNearestStep] = useState(null);
	const [expandVote, setExpandRating] = useState(false);

	useEffect(() => {
		let isFond = false;
		aboutCourseSteps.forEach(step => {
			if (!isFond) {
				if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
					console.log('шаг', step);
					setNearestStep(step);
					isFond = true;
				}
			}
		});
	}, [aboutCourseSteps]);

	const onClose = () => {
		dispatch(setActiveModal(isLastStep ? 'modalMarathonComplete' : null));
	};

	const vote = async num => {
		setVoteStars(num);
		await setStepRating(selectedMarathon.author_id, selectedMarathon.course_id, stepId, num);
	};

	const openNextTest = () => {
		dispatch(setActiveModal(null));
		dispatch(openTest(nearestStep.has_test, nearestStep.article_url, nearestStep.title));
	};

	const openVote = () => {
		setExpandRating(true);
	};

	return (
		<ModalCard
			id={id}
			onClose={onClose}
			icon={<Icon56CheckCircleOutline width={48} height={48}/>}
			header="Упражнение завершено"
			caption={
				<>
					<div>
						Вы успешно завершили упражнение.<br/>
						{!hasNoTest ? `Правильных ответов ${rightQuestions}/${totalQuestions}` : null}
					</div>
					{!expandVote &&
					<div
						className="ModalCard__subtitle--closed"
						onClick={openVote}
					>
						Оцените упражнение
					</div>
					}
					{expandVote &&
					<>
						<div className="ModalCard__subtitle">Оцените упражнение</div>
						<RatingSet rating={voteStars} size={22} clickFunc={vote}/>
					</>
					}
				</>
			}
			actionsLayout="vertical"
			actions={!isLastStep && [
				{
					title: 'К следующему упражнению',
					mode: 'primary',
					size: 'xl',
					action: openNextTest,
				},
			]}
		/>
	);
};

ModalExerciseComplete.propTypes = {
	id: PropTypes.string,
	setActiveModal: PropTypes.func,
};

export default ModalExerciseComplete;
