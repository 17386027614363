import * as types from './actionTypes';

export const homeMarathonsSetStudentActiveCourses = studentActiveCourses => ({
    type: types.HOME_MARATHONS_SET_STUDENT_ACTIVE_COURSES,
    studentActiveCourses,
});

export const homeMarathonsSetRecommendedCourses = recommendedCourses => ({
    type: types.HOME_MARATHONS_SET_RECOMMENDED_COURSES,
    recommendedCourses,
});

export const homeMarathonsSetBestAuthors = (bestAuthors, groups) => ({
    type: types.HOME_MARATHONS_SET_BEST_AUTHORS,
    bestAuthors,
    groups,
});

export const homeSecondSetTopCourse = topCourse => ({
    type: types.HOME_SECOND_SET_TOP_COURSE,
    topCourse,
});

export const homeSecondSetRecommendedCourses = recommendedCourses => ({
    type: types.HOME_SECOND_SET_RECOMMENDED_COURSES,
    recommendedCourses,
});

export const homeSecondSetBestAuthors = (bestAuthors, groups) => ({
    type: types.HOME_SECOND_SET_BEST_AUTHORS,
    bestAuthors,
    groups,
});

export const homeSetStudentActiveCourses = studentActiveCourses => ({
    type: types.HOME_PROFILE_SET_ACTIVE_COURSES,
    studentActiveCourses,
});

export const homeSetStudentFavouriteCourses = studentFavouriteCourses => ({
    type: types.HOME_PROFILE_SET_FAVOURITE_COURSES,
    studentFavouriteCourses,
});

export const homeSetStudentCompletedCourses = studentCompletedCourses => ({
    type: types.HOME_PROFILE_SET_COMPLETED_COURSES,
    studentCompletedCourses,
});

export const homeSetSearchText = searchText => ({
    type: types.HOME_SET_SEARCH_TEXT,
    searchText,
});

export const homeSetSearchCourses = searchCourses => ({
    type: types.HOME_SET_SEARCH_COURSES,
    searchCourses,
});

export const homeClearSearchCourses = () => ({
    type: types.HOME_CLEAR_SEARCH_COURSES,
});

export const homeSetSearchIsLoading = searchIsLoading => ({
    type: types.HOME_SET_SEARCH_IS_LOADING,
    searchIsLoading,
});

export const homeSelectMarathon = selectedMarathon => ({
    type: types.HOME_SELECT_MARATHON,
    selectedMarathon,
});

export const homeAboutSetMarathonsAmount = aboutMarathonsAmount => ({
    type: types.HOME_ABOUT_SET_MARATHONS_AMOUNT,
    aboutMarathonsAmount,
});

export const homeAboutSetCourseSteps = aboutCourseSteps => ({
    type: types.HOME_ABOUT_SET_COURSE_STEPS,
    aboutCourseSteps,
});

export const homeAboutSetCoursePublic = aboutCoursePublic => ({
    type: types.HOME_ABOUT_SET_COURSE_PUBLIC,
    aboutCoursePublic,
});

export const homeResetCourseInfo = () => ({
    type: types.HOME_RESET_COURSE_INFO,
});

export const homeAboutSelectAuthorId = selectedAuthorId => ({
    type: types.HOME_ABOUT_SELECT_AUTHOR_ID,
    selectedAuthorId,
});

export const homeAboutSetAuthor = aboutAuthor => ({
    type: types.HOME_ABOUT_SET_AUTHOR,
    aboutAuthor,
});

export const homeAboutSetAuthorMarathons = aboutAuthorMarathons => ({
    type: types.HOME_ABOUT_SET_AUTHOR_MARATHONS,
    aboutAuthorMarathons,
});

export const homeResetAuthorInfo = () => ({
    type: types.HOME_RESET_AUTHOR_INFO,
});

export const homeAboutSetAuthorBread = aboutAuthorBread => ({
    type: types.HOME_ABOUT_SET_AUTHOR_BREAD,
    aboutAuthorBread,
});

export const homeAboutSetAuthorIsMember = aboutAuthorIsMember => ({
    type: types.HOME_ABOUT_SET_AUTHOR_IS_MEMBER,
    aboutAuthorIsMember,
});

export const homeAboutSetSelectedMarathonIsActiveCourse = isActiveCourse => ({
    type: types.HOME_ABOUT_SET_SELECTED_MARATHON_IS_ACTIVE_COURSE,
    isActiveCourse,
});

export const homeAboutSetSelectedMarathonIsFavourite = isFavourite => ({
    type: types.HOME_ABOUT_SET_SELECTED_MARATHON_IS_FAVOURITE,
    isFavourite,
});

export const homeAboutSetSelectedMarathonIsCompleteCourse = isCompleteCourse => ({
    type: types.HOME_ABOUT_SET_SELECTED_MARATHON_IS_COMPLETE_COURSE,
    isCompleteCourse,
});

export const homeAboutSetCourseStepsIsStepPassed = stepId => ({
    type: types.HOME_ABOUT_SET_COURSE_STEPS_IS_TEST_PASSED,
    stepId,
});

export const homeAboutSetCourseStepsIsArticleRead = stepId => ({
    type: types.HOME_ABOUT_SET_COURSE_STEPS_IS_ARTICLE_READ,
    stepId,
});

export const homeAboutSetCourseStep = step => ({
    type: types.HOME_ABOUT_SET_COURSE_STEP,
    step,
});

export const homeAboutSetIsNextCourseStart = isNextCourseStart => ({
    type: types.HOME_ABOUT_SET_IS_NEXT_COURSE_START,
    isNextCourseStart,
});
