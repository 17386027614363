import React from 'react';
import { IconStar } from './IconSvg';
import '../../styles/rating-set.scss';
import '../../styles/rating-stars.scss';

const RatingSet = ({ size, rating, clickFunc }) => {

	const clickStar = num => {
		clickFunc(num);
	};

    return (
        <div className="rating-set">
					<IconStar onClick={() => clickStar(1)} size={size} color={+rating >= 1}  colBlue />
					<IconStar onClick={() => clickStar(2)} size={size} color={+rating >= 2} colBlue />
					<IconStar onClick={() => clickStar(3)} size={size} color={+rating >= 3} colBlue />
					<IconStar onClick={() => clickStar(4)} size={size} color={+rating >= 4} colBlue />
					<IconStar onClick={() => clickStar(5)} size={size} color={+rating >= 5} colBlue />
        </div>
    );
};

export default RatingSet;
