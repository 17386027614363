import * as types from './actionTypes';

export const setActiveModal = (activeModal) => ({
    type: types.SET_ACTIVE_MODAL,
    activeModal,
});

export const setTabs = (tabIndex) => ({
    type: types.SET_TAB,
    tabIndex,
});

export const setStartInfoLoaded = () => ({
	type: types.SET_START_INFO_LOADED,
});
