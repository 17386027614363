export const HOME_MARATHONS_SET_STUDENT_ACTIVE_COURSES = 'HOME_MARATHONS_SET_STUDENT_ACTIVE_COURSES';
export const HOME_MARATHONS_SET_BEST_AUTHORS = 'HOME_MARATHONS_SET_BEST_AUTHORS';
export const HOME_MARATHONS_SET_RECOMMENDED_COURSES = 'HOME_MARATHONS_SET_RECOMMENDED_COURSES';
export const HOME_SECOND_SET_TOP_COURSE = 'HOME_SECOND_SET_TOP_COURSE';
export const HOME_SECOND_SET_BEST_AUTHORS = 'HOME_SECOND_SET_BEST_AUTHORS';
export const HOME_SECOND_SET_RECOMMENDED_COURSES = 'HOME_SECOND_SET_RECOMMENDED_COURSES';
export const HOME_PROFILE_SET_ACTIVE_COURSES = 'HOME_PROFILE_SET_ACTIVE_COURSES';
export const HOME_PROFILE_SET_FAVOURITE_COURSES = 'HOME_PROFILE_SET_FAVOURITE_COURSES';
export const HOME_PROFILE_SET_COMPLETED_COURSES = 'HOME_PROFILE_SET_COMPLETED_COURSES';
export const HOME_SET_SEARCH_TEXT = 'HOME_SET_SEARCH_TEXT';
export const HOME_SET_SEARCH_COURSES = 'HOME_SET_SEARCH_COURSES';
export const HOME_CLEAR_SEARCH_COURSES = 'HOME_CLEAR_SEARCH_COURSES';
export const HOME_SET_SEARCH_IS_LOADING = 'HOME_SET_SEARCH_IS_LOADING';
export const HOME_SELECT_MARATHON = 'HOME_SELECT_MARATHON';
export const HOME_ABOUT_SET_MARATHONS_AMOUNT = 'HOME_ABOUT_SET_MARATHONS_AMOUNT';
export const HOME_ABOUT_SET_COURSE_STEPS = 'HOME_ABOUT_SET_COURSE_STEPS';
export const HOME_ABOUT_SET_COURSE_PUBLIC = 'HOME_ABOUT_SET_COURSE_PUBLIC';
export const HOME_RESET_COURSE_INFO = 'HOME_RESET_COURSE_INFO';
export const HOME_ABOUT_SELECT_AUTHOR_ID = 'HOME_ABOUT_SELECT_AUTHOR_ID';
export const HOME_ABOUT_SET_AUTHOR = 'HOME_ABOUT_SET_AUTHOR';
export const HOME_ABOUT_SET_AUTHOR_MARATHONS = 'HOME_ABOUT_SET_AUTHOR_MARATHONS';
export const HOME_RESET_AUTHOR_INFO = 'HOME_RESET_AUTHOR_INFO';
export const HOME_ABOUT_SET_AUTHOR_BREAD = 'HOME_ABOUT_SET_AUTHOR_BREAD';
export const HOME_ABOUT_SET_AUTHOR_IS_MEMBER = 'HOME_ABOUT_SET_AUTHOR_IS_MEMBER';
export const HOME_ABOUT_SET_SELECTED_MARATHON_IS_ACTIVE_COURSE = 'HOME_ABOUT_SET_SELECTED_MARATHON_IS_ACTIVE_COURSE';
export const HOME_ABOUT_SET_SELECTED_MARATHON_IS_FAVOURITE = 'HOME_ABOUT_SET_SELECTED_MARATHON_IS_FAVOURITE';
export const HOME_ABOUT_SET_SELECTED_MARATHON_IS_COMPLETE_COURSE =
    'HOME_ABOUT_SET_SELECTED_MARATHON_IS_COMPLETE_COURSE';
export const HOME_ABOUT_SET_COURSE_STEPS_IS_TEST_PASSED = 'HOME_ABOUT_SET_COURSE_STEPS_IS_TEST_PASSED';
export const HOME_ABOUT_SET_COURSE_STEPS_IS_ARTICLE_READ = 'HOME_ABOUT_SET_COURSE_STEPS_IS_ARTICLE_READ';
export const HOME_ABOUT_SET_COURSE_STEP = 'HOME_ABOUT_SET_COURSE_STEP';
export const HOME_ABOUT_SET_IS_NEXT_COURSE_START = 'HOME_ABOUT_SET_IS_NEXT_COURSE_START';
