import * as types from './actionTypes';

export const testSetBreadcumb = breadcumb => ({
    type: types.TEST_SET_BREADCUMB,
    breadcumb,
});

export const testSetTest = test => ({
    type: types.TEST_SET_TEST,
    test,
});

export const testSetTestsWithoutAnswers = testsWithoutAnswers => ({
    type: types.TEST_SET_TESTS_WITHOUT_ANSWERS,
    testsWithoutAnswers,
});

export const testRemoveAnsweredQuestions = () => ({
    type: types.TEST_REMOVE_ANSWERED_QUESTIONS,
});

export const testReset = () => ({
    type: types.TEST_RESET,
});

export const testNextQuestion = () => ({
    type: types.TEST_NEXT_QUESTION,
});

export const testSetStepId = stepId => ({
    type: types.TEST_SET_STEP_ID,
    stepId,
});

export const testSetPrevTestInfo = (stepId, totalQuestions, rightQuestions) => ({
    type: types.TEST_SET_PREV_TEST_INFO,
    stepId,
    totalQuestions,
    rightQuestions,
});

export const setTestShowOnly = isTestShowOnly => ({
    type: types.TEST_SET_TEST_SHOW_ONLY,
    isTestShowOnly,
});

export const setRightQuestionsModalFrom = (rightQuestions) => ({
	type: types.TEST_SET_RIGHT_QUESTIONS_MODAL_FROM,
	rightQuestions
});

export const setRightQuestionsModalTo = (totalQuestions) => ({
	type: types.TEST_SET_RIGHT_QUESTIONS_MODAL_TO,
	totalQuestions
});

export const setLastRightQuestionsModalFrom = (lastRightQuestions) => ({
	type: types.TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_FROM,
	lastRightQuestions
});

export const setLastRightQuestionsModalTo = (lastTotalQuestions) => ({
	type: types.TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_TO,
	lastTotalQuestions
});

export const setTestIsLastStep = (isLastStep) => ({
	type: types.TEST_SET_IS_LAST_STEP,
	isLastStep
});
