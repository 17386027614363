import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { setStudentInterest } from '../../services/vk';

import { Panel, PanelHeader, FixedLayout, Button, Div } from '@vkontakte/vkui';

import CategoryCheckList from '../../component/CategoryCheckList';
import '../../../styles/welcome.scss';


const Onboard = ({ id }) => {
	const dispatch = useDispatch();
	const selectedCategories = useSelector((store) => store.student.selectedCategoriesIds);
	const categories = useSelector((store) => store.student.categories);

	const saveStudentInterests = async () => {

		if (process.env.NODE_ENV === 'development') {
			dispatch(push('marathon'));
		} else {
			const setInterestResult = await setStudentInterest(selectedCategories);

			if (setInterestResult.result === 'success' && setInterestResult.data === 1) {
				dispatch(push('marathon'));
			} else {
				/*открываем попаут произошла неизвестная ошибка*/
			}
		}
	};

    return (
        <Panel
					id={id}
				>
            <PanelHeader separator={false} transparent />
            <div className="onboard-title">Выбор категории</div>
            <div className="onboard-subtitle">Выберите три или более интересующих Вас темы</div>
            <CategoryCheckList
							categories={categories}
						/>
            <FixedLayout className="layout-white" vertical="bottom">
                <Div>
                    <Button
											size="xl"
											onClick={saveStudentInterests}
											disabled={selectedCategories.length < 3}
										>
                        Продолжить
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

Onboard.propTypes = {
    id: PropTypes.string,
    goStart: PropTypes.func,
};

export default Onboard;
