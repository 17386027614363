import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ModalCard from '@vkontakte/vkui/dist/components/ModalCard/ModalCard';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import { studentStartCourse } from '../services/vk';
import { homeAboutSetSelectedMarathonIsActiveCourse } from '../store/home/actions';
import { setActiveModal } from '../store/globals/actions';
import { openTest } from '../services/_functions';
import { goBack } from 'react-router-redux';

const ModalSubs = ({ id }) => {
	const dispatch = useDispatch();
	const selectedMarathon = useSelector(state => state.home.selectedMarathon);
	const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
	const [nearestStep, setNearestStep] = useState(null);

	useEffect(() => {
		let isFond = false;
		aboutCourseSteps.forEach(step => {
			if (!isFond) {
				if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
					setNearestStep(step);
					isFond = true;
				}
			}
		});
	}, []);

	const joinMarathon = async () => {
		const startResult = await studentStartCourse(selectedMarathon.author_id, selectedMarathon.course_id);
		if (startResult.result === 'success' && startResult.data === 1) {
			dispatch(homeAboutSetSelectedMarathonIsActiveCourse(true));
		}
	};

	const closeMdl = () => {
		dispatch(setActiveModal(null))
	};

	const clickLater = () => {
		joinMarathon();
		closeMdl();
	};

	const clickGo = () => {
		joinMarathon();
		closeMdl();
		dispatch(openTest(nearestStep.has_test, nearestStep.article_url, nearestStep.title));
	};

	return (
		<ModalCard
			id={id}
			onClose={closeMdl}
			className="modal"
			header="Подписка на марафон"
			caption={
				<>
                    <span>
                        Начать прохождение марафона «{selectedMarathon && selectedMarathon.title}»? Вы
                        можете начать прохождение позже в Вашем профиле.
                    </span>
					<Button
						mode="tertiary"
						onClick={clickLater}>
						Пройти позже
					</Button>
				</>
			}
			actionsLayout="vertical"
			actions={[
				{
					title: 'Перейти к прохождению',
					mode: 'primary',
					size: 'xl',
					action: clickGo
				},
			]}
		/>
	);
};

ModalSubs.propTypes = {
	id: PropTypes.string,
	setActiveModal: PropTypes.func,
};

export default ModalSubs;
