import React, { useState, useCallback, useEffect } from 'react';
import useDidMountEffect from '../hooks/useDidMountEffect';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { debounce } from 'lodash';

import { Panel, PanelHeader, FixedLayout, Search, Div, Spinner } from '@vkontakte/vkui';

import MarathonList from '../component/MarathonList';
import BestAuthorList from '../component/BestAuthorList';
import EmptySearch from '../component/EmptySearch';
import { getRecommendedCourses, getSearchCourses } from '../services/vk';
import {
	homeClearSearchCourses,
	homeSecondSetRecommendedCourses,
	homeSetSearchCourses,
} from '../store/home/actions';
import { smoothScrollToTop } from '../services/_functions';

const PanelMarathons = ({ id, history }) => {
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadCounter, setLoadCounter] = useState(12);
	const marathonsStudentActiveCourses = useSelector(state => state.home.tabMarathons.studentActiveCourses);
	const marathonsBestAuthors = useSelector(state => state.home.tabMarathons.bestAuthors);
	const marathonsRecommendedCourses = useSelector(state => state.home.tabMarathons.recommendedCourses);
	const searchCourses = useSelector(state => state.home.search.searchCourses);

	const manageSearchHandler = (text) => {
		if (text.length === 0) {
			clearSearchCourses();
			return;
		}

		if (text.length >= 3) {
			setLoading(true);
			searchHandler(text);
		}

		setSearchValue(text);
	};

	const clearSearchCourses = () => {
		dispatch(homeClearSearchCourses);
		setSearchValue('');
	};

	const searchHandler = useCallback(debounce(async (text) => {
		const searchCoursesResult = await getSearchCourses(text, 0);
		if (searchCoursesResult.result === 'success') {
			dispatch(homeSetSearchCourses(searchCoursesResult.data));
			setLoading(false);
		}
	}, 1000), []);

	const infiniteScrollLoader = async () => {
		const getDocHeight = () => {
			const D = document;
			return Math.max(
				D.body.scrollHeight, D.documentElement.scrollHeight,
				D.body.offsetHeight, D.documentElement.offsetHeight,
				D.body.clientHeight, D.documentElement.clientHeight,
			);
		};

		const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
		const docHeight = getDocHeight();
		const trackLength = docHeight - windowHeight;
		const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

		if (trackLength === scrollTop) {

			setLoading(true);
			const loadedSearchCourses = await getSearchCourses(searchValue, 0, 0, loadCounter + 12);
			if (loadedSearchCourses.result === 'success') {
				if (loadedSearchCourses.data.total === searchCourses.total || searchValue.length === 0) {
					window.removeEventListener('scroll', infiniteScrollLoader);
					dispatch(homeSecondSetRecommendedCourses(loadedSearchCourses.data));
					setLoading(false);
				} else {
					dispatch(homeSecondSetRecommendedCourses(loadedSearchCourses.data));
					setLoadCounter(loadCounter + 12);
					setLoading(false);
				}
			}
		}
	};

	useDidMountEffect(() => {
		window.addEventListener('scroll', infiniteScrollLoader);
		return () => window.removeEventListener('scroll', infiniteScrollLoader);
	}, [loadCounter]);

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	return (
		<Panel id={id}>
			<PanelHeader separator={false}>Марафоны</PanelHeader>
			<FixedLayout vertical="top">
				<Search
					value={searchValue}
					onChange={(e) => manageSearchHandler(e.target.value)}
				/>
			</FixedLayout>
			<div style={{ marginTop: '52px' }}/>
			{loading && <Spinner/>}
			{
				searchCourses.items.length > 0 && !loading && searchValue.length >= 3 &&
				<MarathonList
					title="Результаты поиска"
					marathons={searchCourses.items}
					history={history}
				/>
			}
			{searchCourses.items.length === 0 && !loading && searchValue.length >= 3 && <EmptySearch />}
			{marathonsStudentActiveCourses.length > 0 && searchValue.length < 3 &&
				<MarathonList
					title="Последние марафоны"
					marathons={marathonsStudentActiveCourses}
					history={history}
				/>
			}
			{/*{marathonsStudentActiveCourses.length === 0 && searchValue.length === 0 &&
				<Div>У вас нет активных курсов</Div>
			}*/}
			{marathonsBestAuthors.length > 0 && searchValue.length < 3 &&
			<BestAuthorList
				name="Лучшие авторы"
				authors={marathonsBestAuthors}
				history={history}
			/>
			}
			{marathonsRecommendedCourses.items.length > 0 && searchValue.length < 3 &&
			<MarathonList
				title="Марафоны"
				marathons={marathonsRecommendedCourses.items}
				history={history}
			/>
			}
			<div style={{ marginTop: '12px' }}/>
		</Panel>
	);
};

PanelMarathons.propTypes = {
	id: PropTypes.string,
};

export default PanelMarathons;
