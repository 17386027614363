import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { homeAboutSetAuthorIsMember } from '../store/home/actions';
import { joinGroup, leaveGroup } from '../services/vk';
import PropTypes from 'prop-types';

import { Div, Button, Avatar } from '@vkontakte/vkui';

import { FireEvent, numberWithKM, wordPad } from '../services/_functions';


const BlockAuthor = ({ id, cover, avatar, name, marathons, ppl, isInvite, isClosed }) => {
	const dispatch = useDispatch();

	const joinThisGroup = async () => {
		const joinResult = await joinGroup(id);
		if (joinResult) {
			dispatch(homeAboutSetAuthorIsMember(true));
		}
	};

	const leaveThisGroup = async () => {
		const leaveGroupResult = await leaveGroup(id);
		if (leaveGroupResult) {
			dispatch(homeAboutSetAuthorIsMember(false));
		}
	};

	const openPublic = () => {
		FireEvent(`https://vk.com/public${id}`);
	};

	return (
		<Div className="author-about">
			<Avatar
				size={72}
				src={avatar}
				onClick={openPublic}
			/>
			<div className="author-about__name">{name}</div>
			<div className="author-about__stats">
				{marathons + wordPad(marathons, ' марафон', ' марафона', ' марафонов')}
				{ppl &&
				<>
					{' · '}
					{numberWithKM(ppl) + wordPad(ppl, ' подписчик', ' подписчика', ' подписчиков')}
				</>
				}
			</div>
			{isClosed === 2 ? null : isInvite !== -1 && isInvite ? (
				<Button onClick={leaveThisGroup} mode="secondary">
					Вы подписаны
				</Button>
			) : (
				<Button onClick={joinThisGroup}>Подписаться</Button>
			)}
		</Div>
	);
};

BlockAuthor.propTypes = {
	avatar: PropTypes.string,
	name: PropTypes.string,
	marathons: PropTypes.number,
	ppl: PropTypes.number,
};

export default BlockAuthor;
