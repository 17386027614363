import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';

const EmptyTab = ({ text, button_text, path_to }) => {
    const dispatch = useDispatch();
    const openEpicMarathon = () => {
        dispatch(push(path_to));
    };

    return (
        <div className="empty-tab">
            <div className="empty-tab__title">{text}</div>
            <Button size="l" onClick={openEpicMarathon}>
                {button_text}
            </Button>
        </div>
    );
};

EmptyTab.propTypes = {
    text: PropTypes.string,
};

export default EmptyTab;
