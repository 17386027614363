import academic40 from '../../image/interests/academy_40.svg';
import business40 from '../../image/interests/business_40.svg';
import stationery40 from '../../image/interests/stationery_40.svg';
import health40 from '../../image/interests/health_40.svg';
import tea40 from '../../image/interests/tea_40.svg';
import stats40 from '../../image/interests/stats_40.svg';
import ads40 from '../../image/interests/ads_40.svg';
import music40 from '../../image/interests/music_40.svg';
import science40 from '../../image/interests/science_40.svg';
import travel40 from '../../image/interests/travel_40.svg';
import family40 from '../../image/interests/family_40.svg';
import beauty40 from '../../image/interests/beauty_40.svg';
import art40 from '../../image/interests/art_40.svg';
import rouble40 from '../../image/interests/rouble_40.svg';
import camera40 from '../../image/interests/camera_40.svg';
import book40 from '../../image/interests/book_40.svg';
import messages40 from '../../image/interests/messages_40.svg';
import tech40 from '../../image/interests/tech_40.svg';
import other40 from '../../image/interests/more_40.svg';

export const icons = {
    academic_disciplines: academic40,
    business: business40,
    design: stationery40,
    health_and_fitness: health40,
    personal_growth: stats40,
    marketing: ads40,
    music: music40,
    boring_science: science40,
    finance_and_accounting: rouble40,
    photo: camera40,
    languages: messages40,
    it_and_development: tech40,
    cooking: tea40,
    traveling: travel40,
    family: family40,
    style_and_beauty: beauty40,
    creation: art40,
    school: book40,
    other: other40,
};
