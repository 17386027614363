import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { setTabs } from '../store/globals/actions';

import {
	Panel,
	PanelHeader,
	Tabs,
	TabsItem,
	FixedLayout,
	Separator,
	CardScroll,
	Card,
	Link,
	Div, PanelSpinner, Button,
} from '@vkontakte/vkui';
import MarathonList from '../component/MarathonList';
import CategoryCheckList from '../component/CategoryCheckList';
import EmptyTab from '../component/EmptyTab';
import {
	homeSecondSetRecommendedCourses,
	homeSetStudentActiveCourses,
	homeSetStudentCompletedCourses,
	homeSetStudentFavouriteCourses,
} from '../store/home/actions';
import { categoriesArrMock, coursesArrMock } from '../services/mock';
import {
	getCategories, getRecommendedCourses,
	getStudent,
	getStudentActiveCourses,
	getStudentCompleteCourses,
	getStudentFavouriteCourses, setStudentInterest,
} from '../services/vk';
import { studentSetCategories, studentSetInterests } from '../store/student/actions';
import { smoothScrollToTop } from '../services/_functions';

const PanelProfile = ({ id, history }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [counterTabActive, setCounterTabActive] = useState(12);
	const [counterTabFav, setCounterTabFav] = useState(12);
	const [counterTabComplete, setCounterTabComplete] = useState(12);
	const tabIndex = useSelector((store) => store.globals.tabIndex);
	const tabProfileStudentActiveCourses = useSelector(store => store.home.tabProfile.studentActiveCourses);
	const tabProfileStudentFavouriteCourses = useSelector(store => store.home.tabProfile.studentFavouriteCourses);
	const tabProfileStudentCompletedCourses = useSelector(store => store.home.tabProfile.studentCompletedCourses);
	const studentCategories = useSelector((store) => store.student.categories);
	const settings = {
		1: {
			request: getStudentActiveCourses,
			counter: counterTabActive,
			setCounter: setCounterTabActive,
			data: tabProfileStudentActiveCourses,
			action: homeSetStudentActiveCourses
		},
		2: {
			request: getStudentFavouriteCourses,
			counter: counterTabFav,
			setCounter: setCounterTabFav,
			data: tabProfileStudentFavouriteCourses,
			action: homeSetStudentFavouriteCourses
		},
		3: {
			request: getStudentCompleteCourses,
			counter: counterTabComplete,
			setCounter: setCounterTabComplete,
			data: tabProfileStudentCompletedCourses,
			action: homeSetStudentCompletedCourses
		}
	};


	useEffect(() => {
		setLoading(true);
		loadContent();
	}, [tabIndex]);

	useEffect(() => {
		if (tabIndex !== 4) {
			window.addEventListener('scroll', infiniteScrollLoader);
			return () => window.removeEventListener('scroll', infiniteScrollLoader);
		}
	}, [counterTabActive, counterTabFav, counterTabComplete, tabIndex]);

	const loadContent = async () => {
		if (tabIndex === 1) {
			if (process.env.NODE_ENV === 'development') {
				dispatch(homeSetStudentActiveCourses(coursesArrMock()));
				setLoading(false);
			} else {
				const studentActiveCoursesResult = await getStudentActiveCourses();
				if (studentActiveCoursesResult.result === 'success') {
					dispatch(homeSetStudentActiveCourses(studentActiveCoursesResult.data));
					setLoading(false);
				}
			}
		}

		if (tabIndex === 2) {
			if (process.env.NODE_ENV === 'development') {
				dispatch(homeSetStudentFavouriteCourses(coursesArrMock()));
				setLoading(false);
			} else {
				const studentFavouriteCoursesResult = await getStudentFavouriteCourses();
				if (studentFavouriteCoursesResult.result === 'success') {
					dispatch(homeSetStudentFavouriteCourses(studentFavouriteCoursesResult.data));
					setLoading(false);
				}
			}
		}

		if (tabIndex === 3) {
			if (process.env.NODE_ENV === 'development') {
				dispatch(homeSetStudentCompletedCourses(coursesArrMock()));
				setLoading(false);
			} else {
				const studentCompletedCoursesResult = await getStudentCompleteCourses();
				if (studentCompletedCoursesResult.result === 'success') {
					dispatch(homeSetStudentCompletedCourses(studentCompletedCoursesResult.data));
					setLoading(false);
				}
			}
		}

		if (tabIndex === 4) {
			if (process.env.NODE_ENV === 'development') {
				dispatch(studentSetCategories(categoriesArrMock()));
				setLoading(false);
			} else {
				const studentCompletedCoursesResult = await getStudentCompleteCourses();
				if (studentCompletedCoursesResult.result === 'success') {
					const getStudentResult = await getStudent();
					const categories = await getCategories();
					dispatch(studentSetInterests(getStudentResult.data.student.interest));
					dispatch(studentSetCategories(categories.data.items));
					setLoading(false);
				}
			}
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	const infiniteScrollLoader = async () => {
		const getDocHeight = () => {
			const D = document;
			return Math.max(
				D.body.scrollHeight, D.documentElement.scrollHeight,
				D.body.offsetHeight, D.documentElement.offsetHeight,
				D.body.clientHeight, D.documentElement.clientHeight,
			);
		};

		const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
		const docHeight = getDocHeight();
		const trackLength = docHeight - windowHeight;
		const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

		if (trackLength === scrollTop) {

			setLoading(true);
			const loadedResult = await settings[tabIndex].request(0, settings[tabIndex].counter + 12);

			if (loadedResult.result === 'success') {
				if (loadedResult.data.total === settings[tabIndex].data.total) {
					window.removeEventListener('scroll', infiniteScrollLoader);
					dispatch(settings[tabIndex].action(loadedResult.data));
					setLoading(false);
				} else {
					dispatch(settings[tabIndex].action(loadedResult.data));
					settings[tabIndex].setCounter(settings[tabIndex].counter + 12);
					setLoading(false);
				}
			}
		}
	};

	return (
		<Panel id={id}>
			<PanelHeader separator={false}>Профиль</PanelHeader>
			<FixedLayout vertical="top">
				<CardScroll className="tabs-scroll">
					<Card>
						<Tabs>
							<TabsItem onClick={() => dispatch(setTabs(1))} selected={tabIndex === 1}>
								Активные
							</TabsItem>
							<TabsItem onClick={() => dispatch(setTabs(2))} selected={tabIndex === 2}>
								Избранные
							</TabsItem>
							<TabsItem onClick={() => dispatch(setTabs(3))} selected={tabIndex === 3}>
								Пройденные
							</TabsItem>
							<TabsItem onClick={() => dispatch(setTabs(4))} selected={tabIndex === 4}>
								Интересы
							</TabsItem>
						</Tabs>
					</Card>
				</CardScroll>
				<Separator/>
			</FixedLayout>
			<div className="tab-offset">
				{tabIndex === 1 && tabProfileStudentActiveCourses.total > 0 &&
				<MarathonList
					marathons={tabProfileStudentActiveCourses.items}
					history={history}
				/>
				}
				{tabIndex === 2 && tabProfileStudentFavouriteCourses.total > 0 &&
				<MarathonList
					marathons={tabProfileStudentFavouriteCourses.items}
					history={history}
				/>
				}
				{tabIndex === 3 && tabProfileStudentCompletedCourses.total > 0 &&
				<MarathonList
					marathons={tabProfileStudentCompletedCourses.items}
					history={history}
				/>
				}
				{tabIndex === 1 && !loading && tabProfileStudentActiveCourses.total === 0 &&
				<EmptyTab
					text="У Вас пока нет активных марафонов"
					button_text="Найти марафоны"
					path_to="marathon"
				/>
				}
				{tabIndex === 2 && !loading && tabProfileStudentFavouriteCourses.total === 0 &&
				<EmptyTab
					text="У Вас пока нет избранных марафонов"
					button_text="Найти марафоны"
					path_to="marathon"
				/>
				}
				{tabIndex === 3 && !loading && tabProfileStudentCompletedCourses.total === 0 &&
				<EmptyTab
					text="У Вас пока нет пройденных марафонов"
					button_text="Найти марафоны"
					path_to="marathon"
				/>
				}
				{tabIndex === 4 &&
					<div>
						<CategoryCheckList
							categories={studentCategories}
							isProfile={true}
						/>
					</div>
				}
				{loading && <PanelSpinner/>}
			</div>
		</Panel>
	);
};

PanelProfile.propTypes = {
	id: PropTypes.string,
};

export default PanelProfile;
