import React from 'react';
import { useDispatch } from 'react-redux';
import { homeSelectMarathon } from '../store/home/actions';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import Header from '@vkontakte/vkui/dist/components/Header/Header';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import RichCell from '@vkontakte/vkui/dist/components/RichCell/RichCell';

import { wordPad } from '../services/_functions';
import RatingStars from './RatingStars';

const MarathonList = ({ marathons, title, separator, history }) => {
	const dispatch = useDispatch();

	const openCourse = (marathon) => {
		dispatch(homeSelectMarathon(marathon));

		if (history && history.location.pathname === '/marathon') {
			dispatch(push('about-marathon'));
		} else if (history && history.location.pathname === '/category') {
			dispatch(push('about-marathon-category'));
		} else if (history && history.location.pathname === '/profile') {
			dispatch(push('about-marathon-profile'));
		} else if (history && history.location.pathname === '/about-author') {
			dispatch(push('about-marathon'));
		} else if (history && history.location.pathname === '/about-author-category') {
			dispatch(push('about-marathon-category'));
		}
	};

 	const renderList = () => {
        return marathons && marathons.map((marathon, i) => (
            <RichCell
                key={i}
                before={
                	<Avatar
										mode="image"
										size={72}
										src={marathon.cover_photo !== undefined &&
											marathon.cover_photo[0].b !== undefined ? marathon.cover_photo[0].b.url : ''
										}
									/>
                }
                caption={
                    <>
                        {marathon.students_count + wordPad(marathon.students_count, ' студент', ' студента', ' студентов')}
                        {' · '}
												{marathon.step_count  + wordPad(marathon.step_count, ' упражнение', ' упражнения', ' упражнений')}
                    </>
                }
                actions={<RatingStars rating={marathon.rating} size={9} />}
                after=""
								onClick={() => openCourse(marathon)}
            >
							{marathon.title}
            </RichCell>
        ));
    };
    return (
        <Group separator={separator}>
            {title ? <Header>{title}</Header> : ''}
            {renderList()}
        </Group>
    );
};

MarathonList.propTypes = {
    item: PropTypes.array,
    title: PropTypes.string,
    separator: PropTypes.bool,
};

export default MarathonList;
