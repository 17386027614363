import React from 'react';
import PropTypes from 'prop-types';
import { IconStar } from './IconSvg';
import '../../styles/rating-stars.scss';

const RatingStars = ({ size, rating, colWhite }) => {
    return (
        <div className="rating-stars">
            <IconStar colWhite={colWhite} size={size} color={+rating >= 1} />
            <IconStar colWhite={colWhite} size={size} color={+rating >= 2} />
            <IconStar colWhite={colWhite} size={size} color={+rating >= 3} />
            <IconStar colWhite={colWhite} size={size} color={+rating >= 4} />
            <IconStar colWhite={colWhite} size={size} color={+rating >= 5} />
        </div>
    );
};

RatingStars.propTypes = {
    size: PropTypes.number,
    rating: PropTypes.number,
    colWhite: PropTypes.string,
};

export default RatingStars;
