import React, { useEffect } from 'react';
import { Panel } from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';

const PanelEmpty = ({ id }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(push('marathon'));
	}, []);


	return (
		<Panel id={id}/>
	)
};

export default PanelEmpty;
