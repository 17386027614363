import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal } from '../store/globals/actions';
import { studentStartCourse } from '../services/vk';
import { homeAboutSetSelectedMarathonIsActiveCourse } from '../store/home/actions';

import { ModalCard} from '@vkontakte/vkui';

import Icon56AddCircleOutline from '@vkontakte/icons/dist/56/add_circle_outline';


const ModalSubscribeRequest = ({ id }) => {
	const dispatch = useDispatch();
	const selectedMarathon = useSelector(state => state.home.selectedMarathon);
	const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
	const [nearestStep, setNearestStep] = useState(null);

	useEffect(() => {
		let isFond = false;
		aboutCourseSteps.forEach(step => {
			if (!isFond) {
				if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
					setNearestStep(step);
					isFond = true;
				}
			}
		});
	}, []);

	const joinMarathon = async () => {
		const startResult = await studentStartCourse(selectedMarathon.author_id, selectedMarathon.course_id);
		if (startResult.result === 'success' && startResult.data === 1) {
			dispatch(homeAboutSetSelectedMarathonIsActiveCourse(true));
		}
		onClose();
	};

	const onClose = () => {
		dispatch(setActiveModal(null));
	};



	return (
		<ModalCard
			id={id}
			onClose={onClose}
			icon={<Icon56AddCircleOutline width={48} height={48}/>}
			header="Подпишитесь на марафон"
			caption="Для доступа к упражнениям нужна подписка на марафон"
			actions={[
				{
					title: 'Подписаться',
					mode: 'primary',
					size: 'xl',
					action: joinMarathon
				},
			]}
		/>
	)
};

export default ModalSubscribeRequest;
