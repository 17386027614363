import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pushHistory } from '../../store/globals/actions';
import { push } from 'react-router-redux';
import * as VKAPI from '../../services/vk';
import PropTypes from 'prop-types';

import { Panel, PanelHeader, Div, Checkbox, Link, Button } from '@vkontakte/vkui';
import Tooltip from '../../component/Tooltip';

const Welcome = ({ id }) => {
		const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
		const [isLoading, setIsLoading] = useState(false);

	const clickBegin = async () => {

			if (process.env.NODE_ENV === 'development') {
				dispatch(push('onboard'));
			} else {
				const createStudentResult = await VKAPI.createStudent();
				if (createStudentResult.result === 'success' && createStudentResult.data === 1) {
					dispatch(push('onboard'));
				} else {
					/*открываем попаут*/
				}
			}
		};

    return (
        <Panel id={id} separator={false}>
            <PanelHeader separator={false} transparent />
            <Div className="welcome">
                <img className="welcome-image" src="/image/welcome.png" alt="welcome" />
                <div className="welcome-title">Учитесь ВКонтакте</div>
                <div className="welcome-subtitle">Выбирайте образовательные марафоны, занимайтесь каждый день</div>
                <div className="welcome-bottom">
                    <Tooltip
											visible={!checked ? '' : 'none'}
											text="Примите условия оферты, чтобы начать обучение"
										/>
                    <Checkbox
											checked={checked}
											onChange={(e) => setChecked(e.target.checked)}
										>
                        Согласен с&nbsp;
											<Link
												/*href="https://vk.com"
												target="_blank"*/
											>
												условиями оферты
											</Link>
                    </Checkbox>
                    <Div>
                        <Button
													size="xl"
													onClick={clickBegin}
													disabled={!checked}>
                            Начать обучение
                        </Button>
                    </Div>
                </div>
            </Div>
        </Panel>
    );
};

Welcome.propTypes = {
    id: PropTypes.string.isRequired,
    nextStep: PropTypes.func,
};

export default Welcome;
