import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';

import { Div } from '@vkontakte/vkui';
import RatingStars from './RatingStars';

import '../../styles/top-marathon.scss';
import { wordPad } from '../services/_functions';
import { homeSelectMarathon } from '../store/home/actions';

const BlockTopMarathon = ({ topCourse, image, title, student, exercise, rating }) => {
	const dispatch = useDispatch();

	const openHandler = () => {
		dispatch(homeSelectMarathon(topCourse));
		dispatch(push('about-marathon-category'))
	};

	return (
		<Div
			onClick={openHandler}
		>
			<div className="top-marathon">
				<div className="top-marathon__image" style={{ backgroundImage: `url(${image})` }}/>
				<div className="top-marathon__info">
					<div className="top-marathon__name">{title}</div>
					<div className="top-marathon__stats">
						{student + wordPad(student, ' студент', ' студента', ' студентов')}
						{' · '}
						{exercise + wordPad(exercise, ' упражнение', ' упражнения', ' упражнений')}
					</div>
					<RatingStars size={9} rating={rating}/>
				</div>
			</div>
		</Div>
	);
};

BlockTopMarathon.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string,
	student: PropTypes.number,
	exercise: PropTypes.number,
	rating: PropTypes.number,
};

export default BlockTopMarathon;
