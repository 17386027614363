import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAboutAuthorCourses, getCourseSteps, getPublic } from '../services/vk';
import {
	homeAboutSetCoursePublic,
	homeAboutSetCourseSteps, homeAboutSetIsNextCourseStart,
	homeAboutSetMarathonsAmount,
} from '../store/home/actions';
import { push } from 'react-router-redux';

import { Separator, Spinner } from '@vkontakte/vkui';

import BlockPanel from '../component/BlockPanel';
import BlockProgram from '../component/BlockProgram';
import BlockAboutAuthor from '../component/BlockAboutAuthor';
import BlockAboutMarathon from '../component/BlockAboutMarathon';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import '../../styles/marathon-about.scss';
import { openTest } from '../services/_functions';
import { testReset, testSetPrevTestInfo } from '../store/test/actions';

const PanelMarathon = ({ id, history }) => {
	const dispatch = useDispatch();
	const selectedMarathon = useSelector(state => state.home.selectedMarathon);
	const aboutCoursePublic = useSelector(state => state.home.aboutCoursePublic);
	const aboutMarathonsAmount = useSelector(state => state.home.aboutMarathonsAmount);
	const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
	const isNextCourseStart = useSelector(state => state.home.isNextCourseStart);
	const [isLoadingSteps, setIsLoadingSteps] = useState(aboutCourseSteps.length === 0);


	useEffect(() => {
		dispatch(testSetPrevTestInfo(0, 0, 0));
		return () => dispatch(homeAboutSetCourseSteps([]))
	}, []);

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			(async () => {

				const authorCourses = await getAboutAuthorCourses(selectedMarathon.author_id, 0, 1);
				if (authorCourses.result === 'success') {
					dispatch(homeAboutSetMarathonsAmount(authorCourses.data.total));
				}

				const publicInfo = await getPublic(-selectedMarathon.author_id);
				if (publicInfo.result === 'success') {
					dispatch(homeAboutSetCoursePublic(publicInfo.data[0]));
				}

				let courseSteps;
				setIsLoadingSteps(true);
				courseSteps = await getCourseSteps(selectedMarathon.author_id, selectedMarathon.course_id);
				if (courseSteps.result === 'success') {
					dispatch(homeAboutSetCourseSteps(courseSteps.data.items));
				}
				setIsLoadingSteps(false);


				if (isNextCourseStart) {
					let isFond = false;
					courseSteps.data.items.forEach(step => {
						if (!isFond) {
							if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
								dispatch(
									openTest(
										step.has_test,
										step.article_url,
										step.title,
										selectedMarathon.is_complete_course,
									),
								);
								isFond = true;
							}
						}
					});

					dispatch(homeAboutSetIsNextCourseStart(false));
				}
			})();
		}
	}, []);

	return (
		<BlockPanel
			id={id}
			name={selectedMarathon.title}
			history={history}
		>
			<BlockAboutMarathon
				cover={
					selectedMarathon.cover_photo !== undefined &&
					selectedMarathon.cover_photo[0] !== undefined &&
					selectedMarathon.cover_photo[0].b !== undefined
						? selectedMarathon.cover_photo[0].b.url
						: ''
				}
				id={selectedMarathon.author_id}
				courseId={selectedMarathon.course_id}
				name={selectedMarathon.title}
				student={selectedMarathon.students_count}
				done={selectedMarathon.is_complete_course !== undefined ? selectedMarathon.is_complete_course : false}
				rating={selectedMarathon.rating && selectedMarathon.rating}
				video={selectedMarathon.video_url !== undefined ? selectedMarathon.video_url : ''}
				description={selectedMarathon.description}
				isActiveCourse={selectedMarathon.is_active_course !== undefined ? selectedMarathon.is_active_course : false}
				isFavorite={selectedMarathon.is_favorite !== undefined ? selectedMarathon.is_favorite : false}
			/>
			<BlockAboutAuthor
				id={aboutCoursePublic.id}
				avatar={aboutCoursePublic.photo_100}
				name={aboutCoursePublic.name}
				marathons={aboutMarathonsAmount}
				ppl={aboutCoursePublic !== undefined ? aboutCoursePublic.members_count : undefined}
			/>
			<Separator style={{ marginTop: '14.5px' }}/>
			{aboutCourseSteps.length > 0 &&
			<BlockProgram title="Программа" steps={aboutCourseSteps}/>}
			{isLoadingSteps && <Spinner style={{marginTop: '20px'}}/>}
		</BlockPanel>
	);
};

PanelMarathon.propTypes = {
	id: PropTypes.string,
	setActiveModal: PropTypes.func,
};

export default PanelMarathon;
