import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import CategoryItem from '../component/CategoryItem';
import { smoothScrollToTop } from '../services/_functions';

const PanelCategories = ({ id }) => {
    const dispatch = useDispatch();
    const categories = useSelector((store) => store.student.categories);
    const openCategory = () => {
        dispatch(push('/category'));
    };

    useEffect(() => {
    	smoothScrollToTop();
		}, []);


    return (
        <Panel id={id}>
            <PanelHeader>Категории</PanelHeader>
            <CategoryItem
							categories={categories}
							openCategory={openCategory}
							dispatch={dispatch}
						/>
        </Panel>
    );
};

PanelCategories.propTypes = {
    id: PropTypes.string,
    openPage: PropTypes.func,
};

export default PanelCategories;
