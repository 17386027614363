import React from 'react';
import PropTypes from 'prop-types';
import SimpleCell from '@vkontakte/vkui/dist/components/SimpleCell/SimpleCell';
import { setCategoryId, setCategoryTitle } from '../store/student/actions';
import { getCategoryInfo } from '../services/vk';
import {
	homeSecondSetBestAuthors,
	homeSecondSetRecommendedCourses,
	homeSecondSetTopCourse,
} from '../store/home/actions';
import { icons } from '../services/icons';

const CategoryItem = ({ categories, openCategory, dispatch }) => {

		const categoryHandler = async (title, id) => {
			dispatch(setCategoryTitle(title));
			dispatch(setCategoryId(id));
			const categoryInfoResult = await getCategoryInfo(id);

			if (categoryInfoResult.result === 'success') {
				dispatch(
					homeSecondSetTopCourse(
						categoryInfoResult.data.top.items[0] !== undefined ? categoryInfoResult.data.top.items[0] : {},
					),
				);
				dispatch(
					homeSecondSetBestAuthors(
						categoryInfoResult.data.authors.items.slice(0, 5),
						categoryInfoResult.data.groups,
					),
				);
				dispatch(homeSecondSetRecommendedCourses(categoryInfoResult.data.recommended));
			}
			openCategory();
		};

    const renderList = () => {
        return categories.map((category) => (
            <SimpleCell
                key={category.id}
                before={<div className="icon-before icon-category" style={{ backgroundImage: `url(${icons[category.tag] !== undefined ? icons[category.tag] : icons.other})` }} />}
                expandable
                onClick={() => categoryHandler(category.name || category.tag, category.id)}
            >
                {category.name}
            </SimpleCell>
        ));
    };
    return <>{renderList()}</>;
};

CategoryItem.propTypes = {
    item: PropTypes.array,
};

export default CategoryItem;
