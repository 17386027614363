import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { View } from '@vkontakte/vkui';
import OnBoard from './OnBoard';
import Welcome from './Welcome';

import '../../../styles/welcome.scss';

const Start = ({ id, activePanel, history }) => {
    return (
        <View
					id={id}
					activePanel={activePanel}
					header={false}
					history={history}
				>
            <Welcome id="welcome"/>
            <OnBoard id="onboard"/>
        </View>
    );
};

Start.propTypes = {
    id: PropTypes.string,
    openView: PropTypes.func,
};

export default Start;
