import React from 'react';
import PropTypes from 'prop-types';

import { SimpleCell, Avatar } from '@vkontakte/vkui';
import { FireEvent, numberWithKM, wordPad } from '../services/_functions';

const BlockAboutAuthor = ({ id, avatar, name, marathons, ppl }) => {

	const openPublic = () => {
		FireEvent(`https://vk.com/public${id}`);
	};

	return (
		<SimpleCell
			className="marathon-about__author"
			expandable
			before={<Avatar size={48} src={avatar}/>}
			description={
				<>
					{marathons + wordPad(marathons, ' марафон', ' марафона', ' марафонов')}{' '}
					{ppl !== undefined
						? '· ' +
						numberWithKM(ppl) +
						(ppl < 1000
							? wordPad(ppl, ' подписчик', ' подписчика', ' подписчиков')
							: ' подписчиков')
						: ''}
				</>
			}
			onClick={openPublic}
		>
			{name}
		</SimpleCell>
	);
};

BlockAboutAuthor.propTypes = {
	avatar: PropTypes.string,
	name: PropTypes.string,
	marathons: PropTypes.number,
	ppl: PropTypes.number,
};

export default BlockAboutAuthor;
