import * as types from './actionTypes';

export const studentSetInterests = interests => ({
    type: types.STUDENT_SET_INTERESTS,
    interests,
});

export const studentSetCategories = categories => ({
    type: types.STUDENT_SET_CATEGORIES,
    categories,
});

export const studentAddSelectedCategoryId = selectedCategoryId => ({
    type: types.STUDENT_ADD_SELECTED_CATEGORY_ID,
    selectedCategoryId,
});

export const studentRemoveSelectedCategoryId = deselectedCategoryId => ({
    type: types.STUDENT_REMOVE_SELECTED_CATEGORY_ID,
    deselectedCategoryId,
});

export const setCategoryTitle = categoryTitle => {
	return {
		type: types.STUDENT_SET_CATEGORY_TITLE,
		categoryTitle
	}
};

export const setCategoryId = categoryId => ({
	type: types.STUDENT_SET_CATEGORY_ID,
	categoryId
});
