import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Header, Group, SimpleCell } from '@vkontakte/vkui';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import { openTest } from '../services/_functions';
import { studentStartCourse } from '../services/vk';
import { homeAboutSetSelectedMarathonIsActiveCourse } from '../store/home/actions';
import { setActiveModal } from '../store/globals/actions';

const BlockProgram = ({ steps, title }) => {
	const dispatch = useDispatch();
	const selectedMarathon = useSelector(state => state.home.selectedMarathon);

	const openThisArticle = async i => {
		if (selectedMarathon.is_complete_course || selectedMarathon.is_active_course !== undefined && selectedMarathon.is_active_course) {
			dispatch(openTest(steps[i].has_test, steps[i].article_url, steps[i].title, selectedMarathon.is_complete_course));
		} else {
			dispatch(setActiveModal('modal-subscribe-request'));
		}
	};

	const renderList = () => {
		return steps.map((step, i) => (
			<SimpleCell
				className="program-item"
				key={i}
				multiline
				expandable
				description={
					<>
						{step.has_test && 'Есть тест'}
						{step.has_test && step.is_test_passed !== undefined &&
						<>
							{' · Пройдено'}
							<Icon16Done fill="939393" width={10} height={10} />
						</>
						}
						{!step.has_test && step.is_article_read && (
							<>
								Пройдено
								<Icon16Done fill="939393" width={10} height={10} />
							</>
						)}
					</>
				}
				onClick={() => openThisArticle(i)}
			>
				{step.title}
			</SimpleCell>
		));
	};
	return (
		<>
			<Group>
				<Header>{title}</Header>
				{renderList()}
			</Group>
			<div style={{ marginTop: '23px' }}/>
		</>
	);
};

BlockProgram.propTypes = {
	item: PropTypes.array,
	name: PropTypes.string,
};

export default BlockProgram;
