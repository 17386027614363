import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/tooltip.scss';

const Tooltip = ({ text, leftArrow, rightArrow, visible }) => {
    const varRightArrow = rightArrow ? 'tooltip--right' : '';
    const varLeftArrow = leftArrow ? 'tooltip--left' : '';
    return (
        <div className={`tooltip ${varRightArrow}${varLeftArrow}`} style={{ display: visible }}>
            {text}
        </div>
    );
};

Tooltip.propTypes = {
    text: PropTypes.string,
    leftArrow: PropTypes.bool,
    rightArrow: PropTypes.bool,
    visible: PropTypes.string,
};

export default Tooltip;
