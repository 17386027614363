import * as types from './actionTypes';

const initialState = {
	accessToken: 0,
	accessTokenGot: false,
	appId: 0,
	startInfoLoaded: false,
	insets: {
		top: '100px',
		bottom: 0,
		left: 0,
		right: 0
	},
	vkId: 0,
};

export const vk = (state = initialState, action) => {
	switch (action.type) {
		case types.VK_ACCESS_TOKEN_GET_SUCCESS:
			return {
				...state,
				accessToken: action.accessToken,
				accessTokenGot: true,
			};

		case types.VK_ACCESS_TOKEN_GET_FAIL:
			return {
				...state,
				accessToken: 0,
				accessTokenGot: false,
			};

		case types.VK_SET_APP_ID:
			return {
				...state,
				appId: action.appId,
			};

		case types.VK_SET_VK_ID:
			return {
				...state,
				vkId: action.vkId,
			};

		case types.VK_INSETS_GET_SUCCESS:
			return {
				...state,
				insets: action.insets,
			};

		default:
			return state;
	}
};
