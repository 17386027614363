import * as types from './actionTypes';

const initialState = {
    breadcumb: '',
    test: {
        count: 0,
        items: [],
        offset: 0,
        total: 0,
    },
    testsWithoutAnswers: [],
    qNum: 0,
    stepId: 0,
    prevTestInfo: {
        stepId: 0,
        totalQuestions: 0,
        rightQuestions: 0,
    },
    isTestShowOnly: false,
	rightQuestions: 0,
	totalQuestions: 0,
	lastRightQuestions: 0,
	lastTotalQuestions: 0,
	isLastStep: false
};

export const test = (state = initialState, action) => {
    switch (action.type) {
        case types.TEST_SET_BREADCUMB:
            return {
                ...state,
                breadcumb: action.breadcumb,
            };

        case types.TEST_SET_TEST:
            return {
                ...state,
                test: action.test,
            };

        case types.TEST_SET_TESTS_WITHOUT_ANSWERS:
            return {
                ...state,
                testsWithoutAnswers: action.testsWithoutAnswers,
            };

        case types.TEST_REMOVE_ANSWERED_QUESTIONS: {
            const oldTest = state.test.items;
            const newTest = [];

            oldTest.forEach(t => {
                if (state.testsWithoutAnswers.includes(t.question_id)) {
                    newTest.push(t);
                }
            });

            return {
                ...state,
                test: {
                    ...state.test,
                    items: newTest,
                    total: newTest.length,
                },
            };
        }

        case types.TEST_RESET: {
            return {
                ...state,
                test: {
                    count: 0,
                    items: [],
                    offset: 0,
                    total: 0,
                },
                qNum: 0,
                testsWithoutAnswers: [],
            };
        }

        case types.TEST_NEXT_QUESTION:
            return {
                ...state,
                qNum: state.qNum + 1,
            };

        case types.TEST_SET_STEP_ID:
            return {
                ...state,
                stepId: action.stepId,
            };

        case types.TEST_SET_PREV_TEST_INFO:
            return {
                ...state,
                prevTestInfo: {
                    stepId: action.stepId,
                    totalQuestions: action.totalQuestions,
                    rightQuestions: action.rightQuestions,
                },
            };

        case types.TEST_SET_TEST_SHOW_ONLY:
            return {
                ...state,
                isTestShowOnly: action.isTestShowOnly,
            };

			case types.TEST_SET_RIGHT_QUESTIONS_MODAL_FROM:
				const { rightQuestions } = action;
				return {
					...state,
					rightQuestions
				};
			case types.TEST_SET_RIGHT_QUESTIONS_MODAL_TO:
				const { totalQuestions } = action;
				return {
					...state,
					totalQuestions
				};
			case types.TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_FROM:
				const { lastRightQuestions } = action;
				return {
					...state,
					lastRightQuestions
				};
			case types.TEST_SET_LAST_RIGHT_QUESTIONS_MODAL_TO:
				const { lastTotalQuestions } = action;
				return {
					...state,
					lastTotalQuestions
				};
			case types.TEST_SET_IS_LAST_STEP:
				const { isLastStep } = action;
				return {
					...state,
					isLastStep
				};

        default:
            return state;
    }
};
