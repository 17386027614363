import React, { useState, useEffect } from 'react';
import { push, goBack, replace } from 'react-router-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
	homeMarathonsSetBestAuthors,
	homeMarathonsSetRecommendedCourses,
	homeMarathonsSetStudentActiveCourses,
} from './js/store/home/actions';
import { getAuthToken, getCategories, getStartInfo, getStudent, initApp } from './js/services/vk';
import { getAppId, getVkId, sleep } from './js/services/_functions';
import { setAppId, setAuthTokenSuccess, setVkId } from './js/store/vk/actions';
import { setActiveModal, setStartInfoLoaded } from './js/store/globals/actions';
import { studentSetCategories, studentSetInterests } from './js/store/student/actions';
import {
	authorsArrMock,
	categoriesArrMock,
	coursesArrMock,
	publicsArrMock,
} from './js/services/mock';
import PropTypes from 'prop-types';

import { ConfigProvider, Epic, Tabbar, TabbarItem, View, ModalRoot } from '@vkontakte/vkui';

import PanelEmpty from './js/panels/PanelEmpty';
import PanelCategories from './js/panels/PanelCategories';
import PanelProfile from './js/panels/PanelProfile';
import PanelMarathons from './js/panels/PanelMarathons';
import PanelTest from './js/panels/PanelTest';
import PanelMarathon from './js/panels/PanelMarathon';
import PanelCategory from './js/panels/PanelCategory';
import PanelAuthor from './js/panels/PanelAuthor';
import Start from './js/panels/Welcome';

import ModalSubs from './js/component/ModalSubs';
import ModalMarathonComplete from './js/component/ModalMarathonComplete';
import ModalExerciseComplete from './js/component/ModalExerciseComplete';

import '@vkontakte/vkui/dist/vkui.css';
import './styles/style.scss';
import Icon28VideoOutline from '@vkontakte/icons/dist/28/video_outline';
import Icon28ListOutline from '@vkontakte/icons/dist/28/list_outline';
import Icon28UserCircleOutline from '@vkontakte/icons/dist/28/user_circle_outline';
import ModalSubscribeRequest from './js/component/ModalSubscribeRequest';
import ModalExerciseCompleteNoTest from './js/component/ModalExerciseCompleteNoTest';

const App = ({ pageId, history }) => {
	const [activePanel, setActivePanel] = useState('empty');
	const [activeStory, setActiveStory] = useState('epicViewMarathons');
	const dispatch = useDispatch();
	const activeModal = useSelector(store => store.globals.activeModal);
	const isLastStep = useSelector(state => state.test.isLastStep);


	useEffect(() => {
		switch (pageId) {
			case '':
				return;
			case 'marathon':
			case 'about-marathon':
			case 'about-author':
			case 'test':
			case 'empty':
				setActiveStory('epicViewMarathons');
				break;
			case 'categories':
			case 'category':
			case 'about-marathon-category':
			case 'about-author-category':
				setActiveStory('epicViewCategories');
				break;
			case 'profile':
			case 'about-marathon-profile':
				setActiveStory('epicViewProfile');
				break;
			case 'welcome':
			case 'onboard':
				setActiveStory('start');
				break;
		}
		setActivePanel(pageId);
	}, [pageId]);

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			dispatch(setAuthTokenSuccess('token_mock'));
			dispatch(studentSetCategories(categoriesArrMock()));
			dispatch(homeMarathonsSetStudentActiveCourses(coursesArrMock().items.slice(0, 3)));
			dispatch(homeMarathonsSetBestAuthors(authorsArrMock().items.slice(0, 5), publicsArrMock()));
			dispatch(homeMarathonsSetRecommendedCourses(coursesArrMock()));


			dispatch(setStartInfoLoaded());
			dispatch(push('test'));
		} else {
			(async () => {
				await dispatch(setAppId(getAppId()));
				await dispatch(setVkId(getVkId()));
				await dispatch(getAuthToken());

				// Проверяем существует ли пользователь и отправляем его на нужную страницу
				const getOpenNextPage = await getStudentInfo();

				// Получаем категории интересов
				const categories = await getAllCategories();
				dispatch(studentSetCategories(categories.items));

				// Получаем стартовую инфу
				const startInfoResult = await getStartInfo();
				if (startInfoResult.result === 'success') {
					dispatch(homeMarathonsSetStudentActiveCourses(startInfoResult.data.active.items.slice(0, 3)));
					dispatch(
						homeMarathonsSetBestAuthors(
							startInfoResult.data.authors.items.slice(0, 5),
							startInfoResult.data.groups,
						),
					);
					dispatch(homeMarathonsSetRecommendedCourses(startInfoResult.data.recommended));
				}

				// Открываем нужную страницу
				dispatch(push(getOpenNextPage));

				dispatch(setStartInfoLoaded());
			})();
		}
	}, []);

	const getAllCategories = async () => {
		const getCategoriesResult = await getCategories();
		if (getCategoriesResult.result === 'fail') {
			await sleep(333);
			await getAllCategories();
		} else {
			return getCategoriesResult.data;
		}
	};

	const getStudentInfo = async () => {
		const getStudentResult = await getStudent();
		if (getStudentResult.result === 'fail') {
			if (
				getStudentResult.e.error_data !== undefined &&
				getStudentResult.e.error_data.error_reason.error_code === 113
			) {
				return 'welcome';
			} else {
				await sleep(333);
				await getStudentInfo();
			}
		} else {
			if (getStudentResult.data.student.interest.length === 0) {
				return 'onboard';
			} else {
				dispatch(studentSetInterests(getStudentResult.data.student.interest));
			}
		}
	};

	const onCloseModal = () => {
		if (activeModal === 'modalExerciseComplete' || activeModal === 'modalExerciseCompleteNoTest') {
			dispatch(setActiveModal(isLastStep ? 'modalMarathonComplete' : null));
		} else {
			dispatch(setActiveModal(null));
		}
	};

	const modals = (
		<ModalRoot activeModal={activeModal} onClose={onCloseModal}>
			<ModalSubs id="modalSubs"/>
			<ModalMarathonComplete id="modalMarathonComplete"/>
			<ModalExerciseComplete id="modalExerciseComplete"/>
			<ModalSubscribeRequest id="modal-subscribe-request"/>
			<ModalExerciseCompleteNoTest id="modalExerciseCompleteNoTest"/>
		</ModalRoot>
	);

	const openBack = () => {
		dispatch(goBack());
	};

	return (
		<ConfigProvider isWebView={true}>
			<Epic
				id="main"
				activeStory={activeStory}
				tabbar={
					<Tabbar className={activeStory === 'start' || pageId === 'test' ? 'hide' : 'show'} shadow>
						<TabbarItem
							onClick={(e) => dispatch(push('marathon'))}
							selected={activeStory === 'epicViewMarathons'}
							data-story="epicViewMarathons"
							text="Марафоны"
						>
							<Icon28VideoOutline/>
						</TabbarItem>
						<TabbarItem
							onClick={(e) => dispatch(push('categories'))}
							selected={activeStory === 'epicViewCategories'}
							data-story="epicViewCategories"
							text="Категории"
						>
							<Icon28ListOutline/>
						</TabbarItem>
						<TabbarItem
							onClick={(e) => dispatch(push('profile'))}
							selected={activeStory === 'epicViewProfile'}
							data-story="epicViewProfile"
							text="Профиль"
						>
							<Icon28UserCircleOutline/>
						</TabbarItem>
					</Tabbar>
				}
			>
				<Start
					id="start"
					activePanel={activePanel}
				/>
				<View
					id="epicViewMarathons"
					activePanel={activePanel}
					onSwipeBack={openBack}
					modal={modals}
				>
					<PanelEmpty id="empty"/>
					<PanelMarathons
						id="marathon"
						history={history}
					/>
					<PanelAuthor
						id="about-author"
						history={history}
					/>
					<PanelMarathon
						id="about-marathon"
						history={history}
					/>
					<PanelTest id="test"/>
				</View>
				<View
					id="epicViewCategories"
					activePanel={activePanel}
					onSwipeBack={openBack}
					modal={modals}
				>
					<PanelCategories id="categories"/>
					<PanelCategory
						id="category"
						history={history}
					/>
					<PanelAuthor
						id="about-author-category"
						history={history}
					/>
					<PanelMarathon
						id="about-marathon-category"
						history={history}
					/>
				</View>
				<View
					id="epicViewProfile"
					activePanel={activePanel}
					onSwipeBack={openBack}
					modal={modals}
				>
					<PanelProfile
						id="profile"
						history={history}
					/>
					<PanelMarathon
						id="about-marathon-profile"
						history={history}
					/>
				</View>
			</Epic>
		</ConfigProvider>
	);
};

App.propTypes = {
	pageId: PropTypes.string,
};

export default App;
