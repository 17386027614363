import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
	homeAboutSetAuthor, homeAboutSetAuthorBread, homeAboutSetAuthorIsMember,
	homeAboutSetAuthorMarathons,
	homeResetCourseInfo, homeSecondSetRecommendedCourses,
} from '../store/home/actions';
import {
	getAboutAuthorCourses,
	getPublic,
	getRecommendedCourses,
	isPublicMember,
} from '../services/vk';
import Separator from '@vkontakte/vkui/dist/components/Separator/Separator';
import BlockPanel from '../component/BlockPanel';
import BlockAuthor from '../component/BlockAuthor';
import MarathonList from '../component/MarathonList';
import '../../styles/author-about.scss';
import { Spinner } from '@vkontakte/vkui';

const PanelAuthor = ({ id, history }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [panelLoading, setPanelLoading] = useState(true);
	const [loadCounter, setLoadCounter] = useState(12);
	const selectedAuthorId = useSelector(state => state.home.selectedAuthorId);
	const aboutAuthor = useSelector(state => state.home.aboutAuthor);
	const aboutAuthorMarathons = useSelector(state => state.home.aboutAuthorMarathons);
	const aboutAuthorIsMember = useSelector(state => state.home.aboutAuthorIsMember);
	const vkId = useSelector(state => state.vk.vkId);

	useEffect( () => {
		(async () => {
			dispatch(homeResetCourseInfo());
			// Получаем марафоны
			const getAuthorMarathonsResult = await getAboutAuthorCourses(selectedAuthorId, 0);
			if (getAuthorMarathonsResult.result === 'success') {
				dispatch(homeAboutSetAuthorMarathons(getAuthorMarathonsResult.data));
			}

			// Получаем инфу паблика
			const getPublicResult = await getPublic(-selectedAuthorId);
			if (getPublicResult.result === 'success') {
				dispatch(homeAboutSetAuthor(getPublicResult.data[0]));
				dispatch(homeAboutSetAuthorBread(getPublicResult.data[0].name));

				// Проверяем участник паблика или нет
				if (getPublicResult.data[0].is_closed === 0) {
					const isPublicMemberResult = await isPublicMember(-selectedAuthorId, vkId);
					if (isPublicMemberResult.result === 'success') {
						dispatch(homeAboutSetAuthorIsMember(!!isPublicMemberResult.data));
					}
				}
			}
			setPanelLoading(false);
		})();
	}, []);

	const infiniteScrollLoader = async () => {
		const getDocHeight = () => {
			const D = document;
			return Math.max(
				D.body.scrollHeight, D.documentElement.scrollHeight,
				D.body.offsetHeight, D.documentElement.offsetHeight,
				D.body.clientHeight, D.documentElement.clientHeight,
			);
		};

		const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
		const docHeight = getDocHeight();
		const trackLength = docHeight - windowHeight;
		const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

		if (trackLength === scrollTop) {

			setLoading(true);
			const loaded = await getAboutAuthorCourses(selectedAuthorId, 0, loadCounter + 12);
			if (loaded.result === 'success') {
				if (loaded.data.total === aboutAuthorMarathons.total) {
					window.removeEventListener('scroll', infiniteScrollLoader);
					dispatch(homeAboutSetAuthorMarathons(loaded.data));
					setLoading(false);
				} else {
					dispatch(homeAboutSetAuthorMarathons(loaded.data));
					setLoadCounter(loadCounter + 12);
					setLoading(false);
				}
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', infiniteScrollLoader);
		return () => window.removeEventListener('scroll', infiniteScrollLoader);
	}, [loadCounter]);

	return (
		<BlockPanel id={id} name="">
			{!panelLoading && <BlockAuthor
				id={aboutAuthor.id}
				cover={aboutAuthor.cover}
				avatar={aboutAuthor !== undefined ? aboutAuthor.photo_100 : ''}
				name={aboutAuthor !== undefined ? aboutAuthor.name : ''}
				marathons={aboutAuthorMarathons.total}
				ppl={aboutAuthor !== undefined  && aboutAuthor.members_count ? aboutAuthor.members_count : undefined}
				isInvite={aboutAuthorIsMember}
				isClosed={aboutAuthor.is_closed}
			/>}
			{!panelLoading && <Separator style={{ marginTop: '12px' }}/>}
			{!panelLoading && <MarathonList
				title="Марафоны"
				marathons={aboutAuthorMarathons.items}
				history={history}
			/>}
			{loading && <Spinner/>}
			<div style={{ marginTop: '12px' }}/>
		</BlockPanel>
	);
};

PanelAuthor.propTypes = {
	id: PropTypes.string,
	setActiveModal: PropTypes.func,
};

export default PanelAuthor;
